<template>
  <div class="d-flex">
    <svg :id="id" :viewBox="`0 0 ${width} ${height + 10}`" class="change-chart">
      <defs>
        <filter id="drop-shadow" height="140%" width="140%" x="-20%" y="-20%">
          <feGaussianBlur in="SourceAlpha" result="blur" stdDeviation="3" />
          <feOffset dx="2" dy="2" in="blur" result="offsetBlur" />
          <feFlood flood-color="black" flood-opacity="0.5" result="offsetColor" />
          <feComposite in="offsetColor" in2="offsetBlur" operator="in" result="offsetShadow" />
          <feMerge>
            <feMergeNode in="offsetShadow" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <text fill="#000" font-size="2.3" text-anchor="middle" x="65" y="2">{{ filteredOptions.title.text }}</text>
      <text fill="#000" font-size="1.5" text-anchor="middle" x="65" y="5">{{ filteredOptions.subtitle.text }}</text>
      <svg:style type="text/css">
        .change-chart {
          max-width: 1000px;
        }
        .change-chart * {
          font-family: 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
        }
        .cls-1,
        .cls-5 {
          fill: none;
        }

        .cls-2 {
          fill: #e6e7e8;
        }

        .cls-3 {
          clip-path: url(#clip-path);
        }

        .cls-5 {
          stroke: #fff;
          stroke-miterlimit: 10;
        }

        .cdd-letters {
          fill: #1b3460;
        }

        .text {
          transform-origin: center center;
        }
        .text-with-shadow {
          filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
        }
      </svg:style>

      <g transform="translate(-5,5)">
        <g transform="translate(5, 0)">
          <template v-for="(l, i) in legendFill" data-name="legend">
            <g :key="i" :transform="`translate(${getLegendFillX(i)},0)`">
              <circle :fill="l.color" cx="2" cy="2" r="0.7"></circle>
              <text fill="#000" font-size="1.8" font-weight="900" text-anchor="start" x="4" y="2.4">{{ l.name }}</text>
            </g>
          </template>
        </g>

        <svg :viewBox="`0 0 10 ${height + 10}`">
          <g :transform="`translate(${-47 + maxLengthXAxis / 1.2}, 7.5)`">
            <template v-for="(s, i2) in filteredOptions.xAxis.categories">
              <g :key="'categories_' + i2">
                <text
                  :y="((height - 5) / filteredOptions.dataValues.length) * i2 + 1"
                  fill="#000000"
                  font-size="1.8"
                  font-weight="100"
                  text-anchor="end"
                  x="0"
                >
                  {{ s }}
                </text>
              </g>
            </template>
          </g>
        </svg>

        <g :transform="`translate(${(maxLengthXAxis + 3) / 1.2 + 2}, 5) scale(${(110 - (maxLengthXAxis / 1.2 - 12)) / 100}, 1)`">
          <svg :viewBox="`0 0 ${110} ${height + 10}`">
            <line :y2="height - 3" stroke="#ccc" stroke-width="0.13" x1="0" x2="0" y1="0" />
            <line :y2="height - 3" stroke="#e6e6e6" stroke-width="0.1" x1="10" x2="10" y1="0" />
            <line :y2="height - 3" stroke="#e6e6e6" stroke-width="0.1" x1="20" x2="20" y1="0" />
            <line :y2="height - 3" stroke="#e6e6e6" stroke-width="0.1" x1="30" x2="30" y1="0" />
            <line :y2="height - 3" stroke="#e6e6e6" stroke-width="0.1" x1="40" x2="40" y1="0" />
            <line :y2="height - 3" stroke="#e6e6e6" stroke-width="0.1" x1="50" x2="50" y1="0" />
            <line :y2="height - 3" stroke="#e6e6e6" stroke-width="0.1" x1="60" x2="60" y1="0" />
            <line :y2="height - 3" stroke="#e6e6e6" stroke-width="0.1" x1="70" x2="70" y1="0" />
            <line :y2="height - 3" stroke="#e6e6e6" stroke-width="0.1" x1="80" x2="80" y1="0" />
            <line :y2="height - 3" stroke="#e6e6e6" stroke-width="0.1" x1="90" x2="90" y1="0" />
            <line :y2="height - 3" stroke="#e6e6e6" stroke-width="0.1" x1="100" x2="100" y1="0" />

            <text :y="height - 1" font-size="1.8" stroke="#666666" stroke-width="0.1" x="-1">0%</text>
            <text :y="height - 1" font-size="1.8" stroke="#666666" stroke-width="0.1" x="9">10%</text>
            <text :y="height - 1" font-size="1.8" stroke="#666666" stroke-width="0.1" x="19">20%</text>
            <text :y="height - 1" font-size="1.8" stroke="#666666" stroke-width="0.1" x="29">30%</text>
            <text :y="height - 1" font-size="1.8" stroke="#666666" stroke-width="0.1" x="39">40%</text>
            <text :y="height - 1" font-size="1.8" stroke="#666666" stroke-width="0.1" x="49">50%</text>
            <text :y="height - 1" font-size="1.8" stroke="#666666" stroke-width="0.1" x="59">60%</text>
            <text :y="height - 1" font-size="1.8" stroke="#666666" stroke-width="0.1" x="69">70%</text>
            <text :y="height - 1" font-size="1.8" stroke="#666666" stroke-width="0.1" x="79">80%</text>
            <text :y="height - 1" font-size="1.8" stroke="#666666" stroke-width="0.1" x="89">90%</text>
            <text :y="height - 1" font-size="1.8" stroke="#666666" stroke-width="0.1" x="96">100%</text>

            <g transform="translate(0,2)">
              <template v-for="(s, i) in filteredOptions.dataValues">
                <template v-for="(d, i2) in s">
                  <g
                    v-if="d.y > 0 && d.relativeY >= 0"
                    :key="i + '_' + i2"
                    :transform="`translate(0, ${((height - 5) / filteredOptions.dataValues.length) * i})`"
                  >
                    <rect :fill="d.color" :width="Math.abs(d.y)" height="2.2" rx="1" ry="1">
                      <title>{{ d.name }}</title>
                    </rect>
                  </g>
                </template>
              </template>
              <template v-for="(s, i) in filteredOptions.dataValues">
                <template v-for="(d, i2) in s">
                  <g
                    v-if="d.relativeY < 0"
                    :key="i + '__' + i2"
                    :transform="`translate(0, ${((height - 5) / filteredOptions.dataValues.length) * i})`"
                  >
                    <g :transform="`translate(${Math.max(0, getOtherValue(i, i2).y + d.relativeY || d.relativeY)},0)`">
                      <rect :fill="d.color" :width="Math.abs(d.relativeY)" height="2.2" rx="1" ry="1">
                        <title>{{ d.name }}</title>
                      </rect>
                    </g>
                  </g>
                </template>
              </template>

              <template v-for="(s, i) in filteredOptions.dataValues">
                <template v-for="(d, i2) in s">
                  <g
                    v-if="d.relativeY < 0"
                    :key="i + '____' + i2"
                    :transform="`translate(-6, ${((height - 5) / filteredOptions.dataValues.length) * i})`"
                  >
                    <g :transform="`translate(${Math.max(5, getOtherValue(i, i2).y + d.relativeY || d.relativeY)},0)`">
                      <text class="text-with-shadow" fill="#fff" font-size="1.6" font-weight="100" text-anchor="end" x="5" y="1.5">
                        {{ d.relativeY }} %
                      </text>
                    </g>
                  </g>

                  <g
                    v-if="d.relativeY > 0 && i2 !== s.length - 1 && getOtherValue(i, i2).relativeY >= 0"
                    :key="i + '_____' + i2"
                    :transform="`translate(-6, ${((height - 5) / filteredOptions.dataValues.length) * i})`"
                  >
                    <g :transform="`translate(${i2 === 0 ? d.y + 3 : d.y},0.2)`" filter="url(#drop-shadow)">
                      <text
                        :fill="d.color"
                        :y="i2 % 2 === 0 ? -1 : +4"
                        class="text-with-shadow"
                        font-size="1.6"
                        font-weight="100"
                        text-anchor="start"
                        x="0"
                      >
                        +{{ d.relativeY }} %
                      </text>
                    </g>
                  </g>
                  <g
                    v-if="d.realY > 0 && i2 === s.length - 1 && getOtherValue(i, i2).relativeY >= 0"
                    :key="i + '_____' + i2"
                    :transform="`translate(-6, ${((height - 5) / filteredOptions.dataValues.length) * i})`"
                  >
                    <g :transform="`translate(${d.y},0.2)`" filter="url(#drop-shadow)">
                      <text
                        :fill="d.color"
                        :y="i2 % 2 === 0 ? -1 : +4"
                        class="text-with-shadow"
                        font-size="1.6"
                        font-weight="100"
                        text-anchor="start"
                        x="0"
                      >
                        {{ d.realY }} %
                      </text>
                    </g>
                  </g>
                </template>
              </template>
            </g>
          </svg>
        </g>
      </g>
    </svg>
    <v-menu bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item link style="cursor: pointer" @click="SVGToPNG">
          <v-list-item-subtitle>
            {{ $t('download_png_image') }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item link style="cursor: pointer" @click="SVGToFile">
          <v-list-item-subtitle>
            {{ $t('download_svg_image') }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import uuid from 'uuid/v4';

export default {
  name: 'ChangeChart',
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    chartTitle: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    maxLengthXAxis() {
      return this.filteredOptions.xAxis.categories.reduce((acc, cur) => {
        return acc.length > cur.length ? acc : cur;
      }, []).length;
    },
    id() {
      return 'change-chart-' + uuid();
    },
    legendFill() {
      return Array.from(
        new Set(
          this.filteredOptions.series.map(s => {
            return { color: s.color /*s.data?.[i]?.color*/, name: s.name };
          })
        )
      );
    },
    filteredOptions() {
      const options = { ...this.options };

      const dataValues = [];
      options.series?.[0]?.data.map((d, i) => {
        let values = [{ ...d }];
        for (let j = 1; j < options.series.length; j++) {
          const value = { ...options.series[j].data[i] };
          values.push(value);
        }
        if (values.length > 2) {
          values = orderBy(values, ['realY'], ['asc']);
        }
        values.forEach((v, i) => {
          if (i === 0) {
            v.relativeY = 0;
          } else {
            v.relativeY = v.realY - values[i - 1].realY;
          }
          if (v.realY === 0) {
            v.relativeY = 0;
          }
        });
        if (values.length <= 2) {
          values = orderBy(values, ['relativeY'], ['asc']);
        }

        dataValues.push(values.reverse());
      });
      options.dataValues = dataValues;
      return options;
    },
    height() {
      const height = Math.min(this.filteredOptions.dataValues?.length * 10 + 20, 100);
      return height;
    },
    width() {
      return 130;
    },
  },
  methods: {
    getOtherValue(i, i2) {
      return this.filteredOptions.dataValues?.[i]?.[i2 > 0 ? i2 - 1 : i2 + 1] || { y: 0, relativeY: 0, realY: 0 };
    },
    getLegendFillX(i) {
      let c = 0;
      if (i == 0) return 0;
      for (let j = 0; j < this.legendFill.length; j++) {
        if (j === i) {
          return c;
        }
        c = c + (this.legendFill[j]?.name?.length / 100) * 70 + 10;
      }
    },

    SVGToPNG() {
      const svg = document.querySelector('#' + this.id);

      const data = new XMLSerializer().serializeToString(svg);
      const svgBlob = new Blob([data], {
        type: 'image/svg+xml;charset=utf-8',
      });
      const url = URL.createObjectURL(svgBlob);

      // load the SVG blob to a flesh image object
      const img = new Image();
      img.addEventListener('load', () => {
        // draw the image on an ad-hoc canvas
        const bbox = { width: this.width, height: this.height + 10 };

        const canvas = document.createElement('canvas');
        canvas.width = bbox.width * 25;
        canvas.height = bbox.height * 25;

        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0, canvas.width, canvas.height);

        URL.revokeObjectURL(url);

        // trigger a synthetic download operation with a temporary link
        const a = document.createElement('a');
        a.download = 'image.png';
        document.body.appendChild(a);
        a.href = canvas.toDataURL();
        a.click();
        a.remove();
      });
      img.src = url;
    },
    SVGToFile() {
      const svg = document.querySelector('#' + this.id);

      const data = new XMLSerializer().serializeToString(svg);
      const svgBlob = new Blob([data], {
        type: 'image/svg+xml;charset=utf-8',
      });
      const url = URL.createObjectURL(svgBlob);

      // trigger a synthetic download operation with a temporary link
      const a = document.createElement('a');
      a.download = 'image.svg';
      document.body.appendChild(a);
      a.href = url;
      a.click();
      a.remove();
    },
  },
};
</script>

<style lang="scss" scoped>
.change-chart {
}
</style>
<style>
.text-with-shadow {
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
}
</style>
