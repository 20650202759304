<template>
  <v-container fluid fill-height class="primary py-0">

    <!-- REMEMBER TO DELETE THIS SNACKBAR -->
    <!--<v-snackbar
      :value="true"
      :timeout="-1"
      multi-line
      top
    >
      LoginView.vuen uiComponentProperties objektissa löytyy seuraavat muuttujat:<br /><br />
      <strong>outlined</strong> = kaikille loginin tekstikentille ääriviivat<br />
      <strong>rounded</strong> = kaikki loginin tekstikentät (jos outlined), virheviestit ja buttonit pyöristetyiksi<br />
      <strong>text</strong> = kaikki loginin virheviestien taustat läpinäkyvämmäksi (hyvä jos on tosi tumma tai vaalea tausta sivulla)
      <br /><br />
      <div class="headline">Muista poistaa tämä snackbar koodista!!!!</div>
    </v-snackbar>-->

      <div class="background-image"></div>
    <v-row no-gutters class="fill-height" align="center" style="z-index: 1;"  v-if="!$userInfo">
      <v-col cols="12" md="6" offset-md="3" lg="4" offset-lg="4" xl="4" offset-xl="4" class="login-holder px-3 pt-5 px-xl-16">

        <div class="text-center pb-5">
          <v-avatar color="white" size="120" class="elevation-5">
            <img style="height: auto; width: 90%;" src="/img/signium.png" />

          </v-avatar>

        </div>

        <div class="headline font-weight-bold white--text text-center mb-8">{{ $t('title') }}</div>

        <v-row>
          <v-select v-model="loginType" :items="loginTypes" disabled dark></v-select>
        </v-row>
        <v-row>
          <v-card color="transparent" elevation="0" dark style="width: 100%;" class="ma-0 pa-0">
            <v-row>
              <template v-if="loginType === 'CODE'">
                <login-with-code></login-with-code>
              </template>
              <template v-else-if="loginType === 'PASSWORD'">
                <username-password-login></username-password-login>
              </template>
              <template v-else-if="loginType === 'COGNITO'">
                <custom-sign-in v-if="!isSignUp && !isForgotPassword && !isRequireNewPassword"
                                :is-confirm-signup="isConfirmSignUp"
                                v-bind:signInConfig="signInConfig"
                                :uiComponentProperties="uiComponentProperties" />

                <custom-require-new-password v-if="isRequireNewPassword"
                                             v-bind:requireNewPasswordConfig="options.requireNewPasswordConfig"
                                             :uiComponentProperties="uiComponentProperties" />

                <custom-forgot-password class="forgot-password"
                                        v-if="isForgotPassword"
                                        v-bind:forgotPasswordConfig="forgotPasswordConfig"
                                        :uiComponentProperties="uiComponentProperties" />
              </template>
            </v-row>
          </v-card>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import userApi from "@/api/user";
import UiEventBus from '../UiEventBus'
import UserService from '../service/user.service';
import { components as amplifyComponents, AmplifyEventBus } from 'aws-amplify-vue'
import CustomSignIn from '../components/Login/SignIn.vue';
import CustomForgotPassword from '../components/Login/ForgotPassword.vue';
import CustomRequireNewPassword from '../components/Login/RequireNewPassword.vue';
import UsernamePasswordLogin from "@/components/Login/UsernamePasswordLogin";
import LoginWithCode from "@/components/Login/LoginWithCode";

export default {
  name: "LoginView",
  data() {
    return {
      loginType: 'COGNITO',
      loginTypes: [/*'CODE', 'PASSWORD', */'COGNITO'],
      uiComponentProperties: {
        rounded: false,
        text: false,
        outlined: true,
      },
      signInConfig: {
        header: this.$t('login.login'),
        isSignUpDisplayed: false,
      },
      signUpConfig: {
        defaultCountryCode: '358',
        signUpFields: [
          {
            label: this.$Amplify.I18n.get('Username'),
            key: 'username',
            required: true,
            type: 'string',
            displayOrder: 1
          },
          {
            label: this.$Amplify.I18n.get('Email'),
            key: 'email',
            required: true,
            type: 'string',
            displayOrder: 2
          },
        ],
        hiddenDefaults: ['phone_number', 'username']
      },
      authConfig: {},
      forgotPasswordConfig: {},
      isSignUp: false,
      isConfirmSignUp: false,
      isForgotPassword: false,
      isRequireNewPassword: false,
    }
  },
  computed: {
    options() {
      const defaults = {
        signInConfig: {},
        signUpConfig: {},
        confirmSignUpConfig: {},
        confirmSignInConfig: {},
        forgotPasswordConfig: {},
        mfaConfig: {},
        requireNewPasswordConfig: {}
      };
      return Object.assign(defaults, this.authConfig)
    }
  },
  methods: {
    async getCurrentUser() {
      try {
        const cognitoUser = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.$store.commit('setLoadingOverlay', true);
        UserService.cognitoLogin(cognitoUser);
        const me = await userApi.getMe();
        UserService.setUserInfo(me);
        UiEventBus.$emit('loginSuccess');
      } catch (error) {
        this.$handleApiError(error);
      }
    },
  },
  created() {
    AmplifyEventBus.$on('localUser', user => {
      this.user = user;
      this.options.signInConfig.username = this.user.username;
      this.options.confirmSignInConfig.user = this.user;
      this.options.confirmSignUpConfig.username = this.user.username;
      this.options.requireNewPasswordConfig.user = this.user;
    });
    AmplifyEventBus.$on('authState', state => {
      this.isRequireNewPassword = state === 'requireNewPassword';
      this.isSignUp = state === 'signUp';
      this.isConfirmSignUp = state === 'confirmSignUp';
      this.isForgotPassword = state === 'forgotPassword';
      if (state === 'signedIn') {
        this.getCurrentUser();
      }
    });
  },
  mounted() {
    if (this.$isLoggedIn) {
      this.$store.commit('setLoadingOverlay', false);
      this.$router.push({ name: 'start' });
    }
  },
  components: {
    LoginWithCode,
    UsernamePasswordLogin,
    CustomSignIn,
    CustomForgotPassword,
    CustomRequireNewPassword,
    ...amplifyComponents
  },
}
</script>

<style lang="scss" scoped>
@import './src/sass/variables';
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-image: url('../assets/cdd-bg.png');
  //background-size: cover;
  background-color: $c-primary;

}

@media only screen and (min-width: 600px) {
  .background-image {
    opacity: 1;
  }
}
</style>
