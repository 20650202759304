<template>
  <v-layout>
    <v-row>
      <v-col xs="12" cols="12">
        <v-text-field label="user" v-model="username"></v-text-field>
      </v-col>
      <v-col xs="12" cols="12">
        <v-text-field label="password" v-model="password"></v-text-field>
      </v-col>
      <v-btn @click="login">kirjaudu</v-btn>
    </v-row>
  </v-layout>
</template>

<script>
import axios from "axios";
import UserService from "@/service/user.service";
import userApi from "@/api/user";
import UiEventBus from "@/UiEventBus";

export default {
  name: 'UsernamePasswordLogin',
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {

    async login() {
      try {
        const formData = new FormData();
        formData.append("username", this.username);
        formData.append("password", this.password);
        await axios.post('/api/public/login', formData);
        UserService.springLogin();
        const me = await userApi.getMe();
        UserService.setUserInfo(me);
        UiEventBus.$emit('loginSuccess');
      } catch (error) {
        console.log(error);
      }
    },
  },
}
</script>

<style scoped>

</style>
