<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $t('tenants.edit_tenant') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn dark icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-form ref="form">

          <v-text-field
            v-model="currentTenant.name"
            :label="$t('tenants.organization_name')"
            :rules="[$rules.required]"
            autocomplete="organization"
            class="mt-5 organization_name"
            type="text"
            validate-on-blur
          ></v-text-field>

        </v-form>

        <v-alert :value="showAlert" class="mt-4" outlined type="error">Tarkista, että olet täyttänyt kaikki pakolliset
          kentät.
        </v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :bottom="$vuetify.breakpoint.xsOnly"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly"
               color="default"
               @click="dialog = false">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn :bottom="$vuetify.breakpoint.xsOnly"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly"
               color="primary"
               @click="saveOrganization">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TenantDetailsDialog",
  data() {
    return {
      dialog: false,
      organization: '',
      showAlert: false,
      currentTenant: {},
    }
  },
  methods: {
    async saveOrganization() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }
      try {
        await this.$adminApi.updateTenant(this.currentTenant.id, {
          name: this.currentTenant.name,
          status: this.currentTenant.status,
        });
        this.dialog = false;
        this.$showSuccessNotification(this.$t('tenants.tenant_update_success'));
      } catch (err) {
        this.$handleApiError(err, this.$t('tenants.tenant_update_failed'));
      }
    },
    open(tenant) {
      this.dialog = true;
      this.currentTenant = Object.assign({}, tenant);
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.currentTenant = {};
        this.showAlert = false;
        this.$refs.form.reset();
        this.$emit('on-dismiss');
      }
    }
  },
}
</script>

<style scoped>

</style>
