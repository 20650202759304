import userApi from "./api/user";

// From https://github.com/GoogleChromeLabs/web-push-codelab/blob/master/app/scripts/main.js
function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

async function registerNotifications() {
  try {
    // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // if (userInfo == null || userInfo.email.toLowerCase().indexOf("hubble.fi") === -1) {
    //   console.log('feature still disabled');
    //   return;
    // }

    // Check that features is enabled
    if (!('Notification' in window) || !('serviceWorker' in navigator)) {
      return;
    }

    if (Notification.permission === 'default') {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        console.log('notifications granted')
      }
    }

    if (Notification.permission !== 'granted') {
      return;
    }

    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();

    if (subscription == null) {
      const vapidPublicKey = `BKVIXLOXlUWHmCuQJUTg-JRZ8ARrpc9XyW6nMCdesVCuVQL0J52utwUONoptcwGCo67fqFjMWWve3eO1SN2m0l0`;
      const subscriptionOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(vapidPublicKey),
      };

      const newSubscription = await registration.pushManager.subscribe(subscriptionOptions);
      console.log('subscription created', newSubscription);
      try {
        await userApi.enableSubscription(newSubscription);
      } catch (err) {
        this.$handleApiError(err);
      }
    } else {
      console.log('subscription already exists', subscription);
    }
  } catch (error) {
    console.log(error);
  }
}

export default { registerNotifications };
