import axios from 'axios';

export default {
  setSelectedTenant(tenant) {
    axios.defaults.headers.common['X-tenant-id'] = tenant;
  },
  async getQuestionnaireShareLink(id, answerers = []) {
    return await axios({
      method: 'get',
      url: `/api/tenant/questionnaire/${id}/sharelink?answererIds=${answerers.join(',')}`,
    }).then(r => r.data);
  },
  async getQuestionnaires() {
    return await axios({
      method: 'get',
      url: '/api/tenant/questionnaire',
    }).then(r => r.data);
  },
  async getQuestionnaire(id) {
    return await axios({
      method: 'get',
      url: '/api/tenant/questionnaire/' + id,
    }).then(r => r.data);
  },
  async getIncompleteAnswerers(id) {
    return await axios({
      method: 'get',
      url: '/api/tenant/questionnaire/' + id + '/incomplete-answerers',
    }).then(r => r.data);
  },
  async upsertQuestionnaire(questionnaire) {
    return await axios({
      method: 'post',
      data: questionnaire,

      url: `/api/tenant/questionnaire`,
    }).then(r => r.data);
  },
  async deleteQuestions(id) {
    return await axios({
      method: 'delete',
      url: `/api/tenant/questionnaire/${id}/questions`,
    }).then(r => r.data);
  },
  async addQuestionsFromCsv(id, csv) {
    return await axios({
      method: 'post',
      data: { csv: csv },

      url: `/api/tenant/questionnaire/${id}/csv`,
    }).then(r => r.data);
  },
  async addQuestionsFromXlsx(id, file) {
    const formData = new FormData();
    formData.append('file', file);
    return await axios({
      method: 'post',
      data: formData,
      headers: {
        'content-type': 'multipart/form-data',
      },

      url: `/api/tenant/questionnaire/${id}/xlsx`,
    }).then(r => r.data);
  },
  async addAnswerersFromCsv(id, csv) {
    return await axios({
      method: 'post',
      data: { csv: csv },

      url: `/api/tenant/questionnaire/${id}/csv/answerers`,
    }).then(r => r.data);
  },
  async downloadAnswerReport(id, testers = []) {
    return await axios({
      method: 'get',
      responseType: 'blob',
      url: `/api/tenant/questionnaire/${id}/csv/report?remove=` + testers.join(','),
    }).then(r => r.data);
  },
  async downloadAnswerReportIncludeTestAnswers(id) {
    return await axios({
      method: 'get',
      responseType: 'blob',
      url: `/api/tenant/questionnaire/${id}/csv/report`,
    }).then(r => r.data);
  },
  async answerReportByDemography(id, questionId, testers = [], answerers = []) {
    return await axios({
      method: 'get',
      url:
        `/api/tenant/questionnaire/${id}/report/bydemography/${questionId}?answererIds=${answerers.join(',')}&remove=` + testers.join(','),
    }).then(r => r.data);
  },
  async demographiesCount(ids, testers = [], answerers = []) {
    return await axios({
      method: 'get',
      url:
        `/api/tenant/questionnaire/${ids.join(',')}/report/demographies/count?answererIds=${answerers.join(',')}&remove=` +
        testers.join(','),
    }).then(r => r.data);
  },
  async reportData(ids, demographyQuestionId = null, testers = [], answerers = []) {
    return await axios({
      method: 'get',
      url: `/api/tenant/questionnaire/${ids.join(',')}/report/data?demographyQuestionId=${
        demographyQuestionId || ''
      }&answererIds=${answerers.join(',')}&remove=${testers.join(',')}`,
    }).then(r => r.data);
  },
  async shareLinkReportPdf(url) {
    return await axios({
      method: 'post',
      timeout: 600000,
      data: { url: url },
      url: `/api/tenant/questionnaire/report/pdf`,
    }).then(r => r.data);
  },
};
