<template>
  <hb-basic-page :loading="loading"
                 :subtle-loading="subtleLoading"
                 :title="$t('tenants.management_title')"
                 name="tenant-management">

    <v-col cols="12" md="6">
      <v-card :class="$vuetify.breakpoint.xsOnly ? 'white--text' : ''"
              :color="$vuetify.breakpoint.xsOnly ? 'primary darken-1' : 'white'">
        <v-card-title :class="$vuetify.breakpoint.xsOnly ? 'column justify-center' : ''" primary-title>
          <div>

            <v-avatar :class="tenant.enabled ? 'primary--text' : 'primary--text'"
                      :size="$vuetify.breakpoint.xsOnly ? '90' : '90'"
                      class="tenant-avatar title font-weight-bold text-uppercase rounded-0"
                      color="white"
                      style="border-radius: 0 !important;">
              <template v-if="logo != null">
                <v-img :src="getImageUrl()" contain></v-img>
              </template>
              <template v-else>
                {{ tenant.name ? tenant.name.substring(0, 2) : '' }}
              </template>
            </v-avatar>
          </div>
          <div :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''" class="ml-2">
            <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="text-center text-sm-left">
              {{ tenant.name }}</h3>
            <div class="body-1 text-center text-sm-left"><span>{{ $t('tenants.statuses.' + tenant.status) }}</span>
            </div>
          </div>
        </v-card-title>

        <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'justify-center pt-0' : ''">
          <v-btn :color="$vuetify.breakpoint.xsOnly ? 'primary lighten-3' : 'primary'" class="ma-1" text
                 @click="openEditTenantDialog">{{ $t('tenants.edit_organization') }}
          </v-btn>
          <v-btn class="ma-1" color="primary" text @click="uploadImage">{{ $t('tenants.upload_logo') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <tenant-details-dialog ref="tenantDetailsDialog" @on-dismiss="onTenantDetailsDialogDismiss"/>
    <input ref="imageFileInput" :multiple="false" accept="image/*" style="display:none" type="file"
           @change="onImageFileChanged">
  </hb-basic-page>
</template>

<script>
import TenantDetailsDialog from "../../components/Tenant/TenantManagement/TenantDetailsDialog";
import tenantApi from "../../api/tenant";

export default {
  name: "TenantManagementView",
  components: {
    TenantDetailsDialog: TenantDetailsDialog,
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      tenant: {},
      logo: null,
      savingImage: false,

    }
  },
  methods: {
    openEditTenantDialog() {
      this.$refs.tenantDetailsDialog.open(this.tenant);
    },
    onTenantDetailsDialogDismiss() {
      this.getTenant();
    },
    async getLogo() {
      try {
        this.logo = await tenantApi.getLogo();
      } catch (error) {
        console.log('error loading logo', error);
      }
    },
    async getTenant() {
      if (this.tenant.id) {
        this.subtleLoading = true;
      } else {
        this.loading = true;
      }
      try {
        this.tenant = await tenantApi.getCurrentTenant();
      } catch (error) {
        this.$handleApiError(error);
      }
      this.loading = false;
      this.subtleLoading = false;
    },
    getImageUrl() {
      return `${this.$baseUrl}/api/shared/image/${this.$tenant}/logo?` + new Date().getTime()
    },
    async onImageFileChanged(fileAddedEvent) {
      const image = {
        file: fileAddedEvent.target.files[0],
        filename: fileAddedEvent.target.value.split('\\').pop()
      };

      this.savingImage = true;
      try {
        if (this.logo != null) {
          await tenantApi.deleteLogo(this.logo.key);
          this.logo = null;
        }

        this.logo = await tenantApi.uploadLogo(image.file, image.filename);
      } catch (error) {
        this.$handleApiError(error);
        console.log(error);
      }
      this.savingImage = false;
    },
    uploadImage() {
      this.$refs.imageFileInput.click();
    },
  },
  mounted() {
    this.getTenant();
    this.getLogo();
  },
}
</script>

<style lang="scss">
.user-avatar {
  overflow: hidden;
}

.users-header {
  .v-list__tile {
    height: 30px;
  }
}
</style>
