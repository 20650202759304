import axios from 'axios';

export default {
  setSelectedTenant(tenant) {
    axios.defaults.headers.common['X-tenant-id'] = tenant;
  },
  async getSentEmails(questionnaireId) {
    return await axios({
      method: 'get',
      url: '/api/tenant/emails/' + questionnaireId,
    }).then(r => r.data);
  },
  async sendEmails(questionnaireId, emails, baseUrl) {
    return await axios({
      method: 'post',
      url: '/api/tenant/emails/' + questionnaireId,
      data: {
        baseUrl,
        emails: emails || [],
      },
    }).then(r => r.data);
  },
  async sendReminderEmails(questionnaireId, emails, baseUrl) {
    return await axios({
      method: 'post',
      url: '/api/tenant/emails/reminder/' + questionnaireId,
      data: {
        baseUrl,
        emails: emails || [],
      },
    }).then(r => r.data);
  },
};
