<template>
  <v-dialog v-model="sendEmailDialog" width="600">
    <v-card>
      <v-dialog v-model="showEmailWithoutUrlWarningDialog" persistent width="500">
        <v-card>
          <v-card-title>No <code v-html="'{{URL}}'"></code> in template</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="mt-5">This email template will not include the link to this evaluation.</p>
            <p>Use <code v-html="'{{URL}}'"></code> in the template text to add personalized links to this evaluation.</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="primary" outlined rounded small @click="acceptEmailWithoutUrlAndSend">
              <v-icon>mdi mdi-email-send</v-icon>
              Send Anyway
            </v-btn>
            <v-spacer />
            <v-btn color="error" outlined rounded small @click="cancelEmailTemplateWithoutUrl">
              <v-icon>mdi mdi-close</v-icon>
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-card>
      <v-card-title>Send emails</v-card-title>
      <v-card-text>
        <v-alert v-if="areEmailsSentToSelectedRecipients" color="warning">
          <v-icon>mdi mdi-alert</v-icon>
          Emails have already been sent! Are you sure you want to send those again?
        </v-alert>
        <v-text-field :label="`Email recipients (${emailRecipients.count})`" :value="emailRecipients.string" readonly />
        <v-text-field v-model="questionnaire.emailSubject" label="Email subject" readonly />
        <v-textarea v-model="questionnaire.emailTemplate" label="Email template" readonly />
        <v-alert color="warning"><code v-html="'{{URL}}'"></code> will be changed to answerer's corresponding url. </v-alert>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn color="error" outlined rounded small @click="sendEmailDialog = false">
          <v-icon>mdi mdi-close</v-icon>
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn :loading="loadingSendEmails" color="primary" outlined rounded small @click="sendEmails">
          <v-icon>mdi mdi-email-send</v-icon>
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import questionnaireApi from '@/api/questionnaire';
import emailApi from '@/api/email';

export default {
  name: 'send-emails',
  data() {
    return {
      showEmailWithoutUrlWarningDialog: false,
      sendEmailsWithoutUrl: false,
      loadingSendEmails: false,
      sendEmailDialog: false,
      sentEmails: [],
      questionnaire: {},
    };
  },
  props: {
    emailRecipients: {},
    questionnaireId: {
      type: Number,
      required: true,
    },
    toSendEmailAddresses: {},
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    areEmailsSentToSelectedRecipients() {
      const sentEmailsList = this.sentEmails?.map(e => e.answerer?.email);
      const recipients = this.emailRecipients.emails;
      return recipients.map(email => sentEmailsList.includes(email)).some(a => a === true);
    },
    linkBaseUrl() {
      return `${document.location.origin}/public/#/tenant/${this.$tenant}/questionnaire`;
    },
  },
  watch: {
    async sendEmailDialog(val) {
      if (val) {
        this.sentEmails = await emailApi.getSentEmails(this.questionnaireId);
        this.questionnaire = await questionnaireApi.getQuestionnaire(this.questionnaireId);
      }
      this.$emit('input', val);
    },
    value(val) {
      this.sendEmailDialog = val;
    },
  },
  emits: ['sent', 'input'],
  methods: {
    async sendEmails() {
      if (this.sendEmailsWithoutUrl === false) {
        if (this.questionnaire.emailTemplate == null || !this.questionnaire.emailTemplate?.includes('{{URL}}')) {
          //this.$showErrorNotification('Email template must include {{URL}}');
          this.showEmailWithoutUrlWarningDialog = true;
          return;
        }
      }
      this.loadingSendEmails = true;
      this.sendEmailsWithoutUrl = false;
      try {
        await emailApi.sendEmails(this.questionnaireId, this.toSendEmailAddresses || [], this.linkBaseUrl);
        this.sendEmailDialog = false;

        this.$emit('sent');
        this.$showSuccessNotification('Emails sent!');
      } catch (e) {
        console.error(e);
        this.$showErrorNotification('Error on sending email ' + e);
      }
      this.loadingSendEmails = false;
    },
    acceptEmailWithoutUrlAndSend() {
      this.showEmailWithoutUrlWarningDialog = false;
      this.sendEmailsWithoutUrl = true;
      this.sendEmails();
    },
    cancelEmailTemplateWithoutUrl() {
      this.showEmailWithoutUrlWarningDialog = false;
    },
  },
};
</script>
