<template>
  <v-dialog v-model="sentEmailsDialog" width="850">
    <v-card>
      <v-card-title>Sent emails</v-card-title>
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th></th>
              <th>Email</th>
              <th>Sent</th>
              <th>Subject</th>
              <th>Error</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="loadingSentEmails">
              <tr>
                <td colspan="2">
                  <loading-indicator />
                </td>
              </tr>
            </template>
            <template v-else-if="sentEmails.length === 0">
              <tr>
                <td colspan="2">No sent emails</td>
              </tr>
            </template>
            <template v-for="email in sentEmails" v-else>
              <tr :key="email.id">
                <td>
                  <v-icon v-if="email.error" color="error">mdi mdi-checkbox-blank-circle</v-icon>
                  <v-icon v-else color="success">mdi mdi-checkbox-blank-circle</v-icon>
                </td>
                <td>{{ email.answerer && email.answerer.email }}</td>
                <td>{{ $formatDateTime(email.createdAt) }}</td>
                <td>{{ email.subject }}</td>
                <td>{{ email.error }}</td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn color="error" outlined rounded small @click="sentEmailsDialog = false">
          <v-icon>mdi mdi-close</v-icon>
          {{ $t('close') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import LoadingIndicator from '@/components/LoadingIndicator';

export default {
  name: 'sent-emails',
  components: { LoadingIndicator },
  emits: ['input'],
  data() {
    return {
      sentEmailsDialog: false,
    };
  },
  props: {
    loadingSentEmails: {},
    sentEmails: {},
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(value) {
      this.sentEmailsDialog = value;
    },
    sentEmailsDialog(value) {
      this.$emit('input', value);
    },
  },
};
</script>
