<template>
  <v-container fluid :fill-height="fillHeight" :class="name" :pa-0="noContainerPadding">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;"
                       :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2"
                       height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <v-app-bar color="primary" dark fixed app :extended="false">

      <template v-if="$vuetify.breakpoint.mdAndDown && !showBackButton">
        <hb-main-menu-button/>
      </template>

      <template v-if="showSearch">
        <v-toolbar-title style="margin-left: 0; width: 100%;" class="title">
          <v-text-field
            :placeholder="searchTitle"
            v-model="searchPhrase"
            class="topbar-search"
            style="font-size: inherit;"
            single-line
            full-width
            hide-details
            color="red lighten-3"
          >
            <v-icon style="cursor: pointer;" slot="append">search</v-icon>
          </v-text-field>
        </v-toolbar-title>
      </template>

      <template v-else>
        <hb-back-button v-if="showBackButton" :fallback="this.backButton.fallback"></hb-back-button>
        <v-toolbar-title style="margin-left: 0; width: 100%;" class="title">
          {{ title }}
        </v-toolbar-title>
      </template>

      <v-spacer></v-spacer>

      <template v-if="!!$slots['app-bar-extension']" v-slot:extension>
        <slot name="app-bar-extension"></slot>
      </template>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <v-row v-if="!loading" style="position: relative;" :no-gutters="noGutters">
        <slot></slot>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
export default {
  props: ['name', 'title', 'loading', 'subtleLoading', 'backButton', 'searchTitle', 'noGutters', 'noContainerPadding', 'fillHeight'],
  data() {
    return {
      searchPhrase: '',
    }
  },
  computed: {
    showBackButton() {
      return this.backButton != null && this.backButton.show;
    },
    showSearch() {
      return this.searchTitle != null;
    },
  },
  watch: {
    searchPhrase(to) {
      this.$emit('handle-search', to);
    }
  }
}
</script>

<style lang="scss">
  .topbar-search {
    .v-input__slot {
      padding: 0 !important;

      &:after {
        display: none;
      }
    }
  }
</style>
