<template>
  <hb-basic-page :loading="loading" :showBackButton="false" :title="$t('questionnaire._s')">
    <v-col>
      <v-row>
        <v-col>
          <v-btn :loading="loadingCreate" color="primary" outlined rounded @click="createNewQuestionnaire">
            <v-icon>mdi mdi-plus</v-icon>
            Create new
          </v-btn>
        </v-col>
      </v-row>

      <v-card class="mt-5" elevation="2">
        <v-list v-if="filteredQuestionnaires.length > 0" three-line>
          <template v-for="(item, index) in filteredQuestionnaires">
            <v-list-item :key="index" :to="{ name: 'tenant_questionnaire', params: { tenantId: $tenant, id: item.id } }">
              <v-list-item-icon>
                <v-icon class=""> mdi-chat-question</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >{{ item.nameEn }} <span v-if="item.type" style="font-style: italic">({{ item.type }})</span>
                </v-list-item-title>
                <v-list-item-subtitle v-text="item.descriptionEn"></v-list-item-subtitle>
                <v-list-item-subtitle v-text="$formatDateTime(item.createdAt)"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index < questionnaires.length - 1" :key="`divider_${index}`"></v-divider>
          </template>
        </v-list>
        <v-list v-else>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >No evaluations found
                <template v-if="selectedQuestionnaireType">
                  for evaluation type <strong>{{ selectedQuestionnaireType }}</strong></template
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </hb-basic-page>
</template>

<script>
import questionnaireApi from '@/api/questionnaire';

export default {
  name: 'QuestionnaireListView',
  components: {},
  created() {
    questionnaireApi.setSelectedTenant(this.$tenant);
  },
  data() {
    return {
      loading: false,
      importDialog: false,
      loadingCreate: false,
      importCSVText: '',
      questionnaires: [],
    };
  },
  methods: {
    async getQuestionnaires() {
      this.loading = true;
      this.questionnaires = await questionnaireApi.getQuestionnaires();
      this.loading = false;
    },
    async reloadAll() {
      this.getQuestionnaires();
    },
    async createNewQuestionnaire() {
      this.loadingCreate = true;
      try {
        const questionnaire = {
          nameFi: null,
          nameSv: null,
          nameEn: 'New evaluation',
          descriptionFi: null,
          descriptionSv: null,
          descriptionEn: null,
          orderer: null,
          type: this.selectedQuestionnaireType,
        };
        await questionnaireApi.upsertQuestionnaire(questionnaire);
        await this.getQuestionnaires();
      } catch (e) {
        console.error(e);
        this.$handleApiError(e);
      }
      this.loadingCreate = false;
    },
  },
  computed: {
    filteredQuestionnaires() {
      if (!this.selectedQuestionnaireType) {
        return this.questionnaires;
      }
      return this.questionnaires.filter(questionnaire => questionnaire.type === this.selectedQuestionnaireType);
    },
    types() {
      return this.$store.state.questionnaireTypes;
    },
    selectedQuestionnaireType() {
      return this.$store.state.selectedQuestionnaireType;
    },
  },
  mounted() {
    this.reloadAll();
  },
};
</script>

<style scoped>
tr.trow:hover {
  background-color: #616161 !important;
}
</style>
