<template>
  <hb-basic-page :back-button="{ show: true, fallback: 'tenant_questionnaires' }" :loading="loading" :title="$t('questionnaire._')">
    <v-col>
      <v-row>
        <v-col cols="12">
          <v-card :key="lang.value" class="mb-5" elevation="2">
            <v-card-title>{{ questionnaire[`name${lang.value}`] }}</v-card-title>
            <v-divider />
            <v-card-text>
              <span class="title">Data</span>
              <v-row class="mt-2">
                <v-col cols="12" md="6">
                  <v-select
                    v-model="questionnaire"
                    :items="[questionnaire]"
                    dense
                    hide-details
                    item-text="name"
                    item-value="id"
                    label="Evaluation"
                    outlined
                    readonly
                    return-object
                    rounded
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="selectedQuestionnaire"
                    :items="filteredQuestionnaires"
                    clearable
                    dense
                    hide-details
                    item-text="name"
                    item-value="id"
                    label="vs evaluation"
                    outlined
                    return-object
                    rounded
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <span class="title">Filters</span>
              <v-row class="mt-2">
                <v-col cols="12" md="4">
                  <v-select
                    v-model="selectedDemography"
                    :items="reportData.demographies"
                    clearable
                    dense
                    hide-details
                    item-text="nameEn"
                    label="Demography"
                    outlined
                    return-object
                    rounded
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="selectedAnswerers"
                    :item-text="item => `${item.firstname} ${item.lastname} (${item.email})`"
                    :items="answerers"
                    clearable
                    dense
                    hide-details
                    label="Answerers"
                    multiple
                    outlined
                    return-object
                    rounded
                    @blur="onAnswerersChange"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="topEntries"
                    :disabled="disabledTopEntries"
                    clearable
                    dense
                    hide-details
                    label="Top n results"
                    outlined
                    placeholder="Insert number"
                    rounded
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-if="languageVersionsEnabled"
                    v-model="lang"
                    :items="langs"
                    dense
                    hide-details
                    outlined
                    return-object
                    rounded
                    style="width: 250px; max-width: 250px"
                  >
                    <template v-slot:prepend-inner>
                      <div class="d-flex justify-center align-center mt-1">
                        <v-icon small>mdi mdi-translate</v-icon>
                      </div>
                    </template>
                    <template v-slot:item="{ parent, item }">
                      <span>{{ item.label }}</span>
                      <small v-if="!questionnaire[`name${item.value}`]" class="error--text ml-2">(not enabled)</small>
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item.label }}

                      <small v-if="!questionnaire[`name${item.value}`]" class="error--text ml-2">(not enabled)</small>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="7">
                  <span class="title">View level</span>
                  <v-row>
                    <v-col cols="12">
                      <v-btn-toggle v-model="viewLevel" mandatory>
                        <v-btn outlined value="area"> Area</v-btn>
                        <v-btn outlined value="dimension"> Dimension</v-btn>
                        <v-btn outlined value="question"> Question</v-btn>
                        <!--:disabled="!!selectedDemography"-->
                        <v-btn :disabled="!selectedDemography" outlined value="demographySplit"> Demography split </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="7">
                  <span class="title">Unit type</span>
                  <v-row>
                    <v-col cols="12">
                      <v-btn-toggle v-model="unitType" mandatory>
                        <v-btn outlined value="percentage"> Percentage</v-btn>
                        <v-btn
                          :disabled="chartType === 'bar_stacked' || chartType === 'star' || viewLevel === 'demographySplit'"
                          outlined
                          value="average"
                        >
                          Average
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <span class="title">Chart</span>
              <v-row>
                <v-col cols="12">
                  <v-btn-toggle v-model="chartType" mandatory>
                    <v-btn
                      :disabled="!!selectedDemography && (viewLevel === 'area' || viewLevel === 'demographySplit')"
                      outlined
                      value="polar"
                    >
                      Polar
                    </v-btn>
                    <v-btn :disabled="!!selectedDemography && viewLevel === 'area'" outlined value="bar"> Bar</v-btn>
                    <v-btn
                      :disabled="(viewLevel !== 'area' && viewLevel !== 'dimension') || unitType === 'average'"
                      outlined
                      value="bar_stacked"
                    >
                      Stacked Bar
                    </v-btn>
                    <v-btn :disabled="!!selectedDemography" outlined value="gap"> Gap</v-btn>
                    <v-btn :disabled="!!selectedDemography" outlined value="highest"> Highest</v-btn>
                    <v-btn :disabled="!!selectedDemography" outlined value="lowest"> Lowest</v-btn>
                    <v-btn :disabled="!!selectedDemography || viewLevel !== 'area' || unitType === 'average'" outlined value="star">
                      Star
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mb-4" />
            <template v-if="reportDataLoading">
              <loading-indicator />
            </template>
            <template v-else-if="!selectedDemography">
              <v-card-text v-for="(answerData, i) in filteredReportDataAnswers" :key="i" :class="chartType">
                <div v-if="answerData.entries.length > 0">
                  <h1 class="mt-10 mb-10">{{ answerData[`stage${lang.value}`] }}</h1>

                  <highcharts
                    v-if="chartType === 'polar'"
                    :key="chartType + '_' + viewLevel + '_1'"
                    :options="getPolarChartOptions(answerData, i)"
                  />

                  <highcharts
                    v-if="chartType === 'bar'"
                    :key="chartType + '_' + viewLevel + '_2'"
                    :options="getBarChartOptions(answerData, i)"
                  />
                  <!--<highcharts v-if="chartType === 'bar_stacked'"
                              :key="chartType + '_' + viewLevel + '_2'"
                              :options="getStackedBarChartOptions(answerData, i)"

                  />-->
                  <change-chart
                    v-if="chartType === 'bar_stacked'"
                    :key="chartType + '_' + viewLevel + '_2'"
                    :lang="lang"
                    :options="getStackedBarChartOptions(answerData, i)"
                  />

                  <highcharts
                    v-if="chartType === 'gap'"
                    :key="chartType + '_' + viewLevel + '_4'"
                    :options="getGapChartOptions(answerData, i)"
                  />
                  <template v-if="chartType === 'star'">
                    <div>
                      <star-chart
                        :key="chartType + '_' + viewLevel + '_6'"
                        :answer-data="answerData"
                        :chart-title="chartTitle"
                        :lang="lang"
                        :questionnaires="[questionnaire, selectedQuestionnaire]"
                        :type="questionnaireTypeForStarChart"
                      />
                    </div>
                  </template>
                  <template v-if="chartType === 'highest' || chartType === 'lowest'">
                    <highcharts
                      v-for="(questionnaire, questionnaireIndex) in reportData.questionnaires"
                      :key="chartType + '_' + viewLevel + '_' + '_6' + '_' + questionnaireIndex"
                      :options="getHighestLowestChartOptions(answerData, i, questionnaireIndex)"
                    />
                  </template>
                  <div v-if="viewLevel === 'question' && chartType === 'bar'">
                    <v-row>
                      <v-col cols="12" md="6">
                        <h4>Answers split</h4>
                        <highcharts
                          :key="chartType + '_' + viewLevel + '_answers_split'"
                          :options="getAnswerSplitOptions(answerData, i, answerData[`stage${lang.value}`])"
                        />
                      </v-col>
                      <v-col cols="12" lg="6">
                        <div class="pt-5">
                          <v-expansion-panels>
                            <v-expansion-panel v-for="(q, i) in answerData.entries" :key="`${q.order}_${i}`">
                              <v-expansion-panel-header>
                                <div>{{ q[`name${lang.value}`] }}</div>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <div>
                                  <highcharts
                                    :key="chartType + '_' + viewLevel + '_answers_split'"
                                    :options="getAnswerSplitOptions({ entries: [q] }, i, q[`name${lang.value}`])"
                                  />
                                </div>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-card-text>
            </template>

            <template v-else-if="selectedDemography && chartType === 'bar' && ['dimension', 'question'].includes(viewLevel)">
              <v-card-text v-for="(answerData, i) in filteredReportDemographyAnswers" :key="`demography_${i}`">
                <div v-if="answerData.entries.length > 0">
                  <h1 class="mt-10 mb-10">{{ answerData[`stage${lang.value}`] }}</h1>
                  <highcharts
                    :key="chartType + '_' + viewLevel + '_5' + selectedDemography.id"
                    :options="getBarChartDemographyOptions(answerData, i)"
                  />
                </div>
              </v-card-text>
            </template>

            <template
              v-else-if="selectedDemography && chartType === 'bar_stacked' && ['area', 'dimension', 'question'].includes(viewLevel)"
            >
              <v-card-text v-for="(answerData, i) in filteredReportDemographyAnswers" :key="`demography_${chartType}_${i}`">
                <div v-if="answerData.entries.length > 0">
                  <h1 class="mt-10 mb-10">{{ answerData[`stage${lang.value}`] }}</h1>
                  <change-chart
                    :key="chartType + '_' + viewLevel + '_stacked__2'"
                    :lang="lang"
                    :options="getStackedBarChartDemographyOptions(answerData, i)"
                  />
                </div>
              </v-card-text>
              <template v-if="['area'].includes(viewLevel)">
                <v-card-text
                  v-for="(answerData, i) in filteredReportDemographyAnswersCombined"
                  :key="`demography_${chartType}_area_stacked${i}`"
                >
                  <div v-if="answerData.entries.length > 0">
                    <h1 class="mt-10 mb-10">{{ answerData[`stage${lang.value}`] }}</h1>
                    <change-chart
                      :key="chartType + '_' + viewLevel + '_stacked_' + i"
                      :lang="lang"
                      :options="getStackedBarChartDemographyReversedOptions(answerData, i)"
                    />
                  </div>
                </v-card-text>
              </template>
            </template>
            <template v-else-if="selectedDemography && chartType === 'polar' && ['dimension', 'question'].includes(viewLevel)">
              <v-card-text v-for="(answerData, i) in filteredReportDemographyAnswers" :key="`demography_${i}`">
                <div v-if="answerData.entries.length > 0">
                  <h1 class="mt-10 mb-10">{{ answerData[`stage${lang.value}`] }}</h1>
                  <highcharts
                    :key="chartType + '_' + viewLevel + '_5_polar' + selectedDemography.id"
                    :options="getPolarChartDemographyOptions(answerData, i)"
                  />
                </div>
              </v-card-text>
            </template>
            <template v-else-if="selectedDemography && chartType === 'bar' && viewLevel === 'demographySplit'">
              <v-card-text>
                <div>
                  <highcharts
                    v-if="selectedDemography && chartType === 'bar' && viewLevel === 'demographySplit'"
                    :key="chartType + '_' + viewLevel + '_' + selectedDemography.id + '_3'"
                    :options="getBarChartDemographySplitOptions(demographiesCount)"
                  />
                </div>
              </v-card-text>
            </template>
            <v-card-text
              v-for="(answerData, i) in /*selectedDemography ? filteredReportDemographyAnswersCombined : */ filteredCombinedDataAnswers"
              v-if="!selectedDemography"
              :key="`combined_${i}`"
              :class="chartType"
            >
              <div v-if="answerData.entries.length > 0">
                <h1 class="mt-10 mb-10">{{ answerData[`stage${lang.value}`] }}</h1>

                <highcharts
                  v-if="chartType === 'polar'"
                  :key="chartType + '_' + viewLevel + '_1'"
                  :options="getPolarChartOptions(answerData, i)"
                />

                <highcharts
                  v-if="chartType === 'bar'"
                  :key="chartType + '_' + viewLevel + '_2'"
                  :options="getBarChartOptions(answerData, i)"
                />
                <!--<highcharts v-if="chartType === 'bar_stacked'"
                            :key="chartType + '_' + viewLevel + '_2'"
                            :options="getStackedBarChartOptions(answerData, i)"

                />-->
                <change-chart
                  v-if="chartType === 'bar_stacked'"
                  :key="chartType + '_' + viewLevel + '_2'"
                  :lang="lang"
                  :options="getStackedBarChartOptions(answerData, i)"
                />

                <highcharts
                  v-if="chartType === 'gap'"
                  :key="chartType + '_' + viewLevel + '_4'"
                  :options="getGapChartOptions(answerData, i)"
                />
                <template v-if="chartType === 'star'">
                  <div>
                    <star-chart
                      :key="chartType + '_' + viewLevel + '_6'"
                      :answer-data="answerData"
                      :chart-title="chartTitle"
                      :lang="lang"
                      :questionnaires="[questionnaire, selectedQuestionnaire]"
                      :type="questionnaireTypeForStarChart"
                    />
                  </div>
                </template>
                <template v-if="chartType === 'highest' || chartType === 'lowest'">
                  <highcharts
                    v-for="(questionnaire, questionnaireIndex) in reportData.questionnaires"
                    :key="chartType + '_' + viewLevel + '_' + '_6' + '_' + questionnaireIndex"
                    :options="getHighestLowestChartOptions(answerData, i, questionnaireIndex)"
                  />
                </template>
                <div v-if="viewLevel === 'question' && chartType === 'bar' && false">
                  <v-row>
                    <v-col cols="12" md="6">
                      <h4>Answers split</h4>
                      <highcharts
                        :key="chartType + '_' + viewLevel + '_answers_split'"
                        :options="getAnswerSplitOptions(answerData, i, answerData[`stage${lang.value}`], true)"
                      />
                    </v-col>
                    <v-col cols="12" lg="6">
                      <div class="pt-5">
                        <v-expansion-panels>
                          <v-expansion-panel v-for="(q, i) in answerData.entries" :key="`${q.order}_${i}`">
                            <v-expansion-panel-header>
                              <div>{{ q[`name${lang.value}`] }}</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div>
                                <highcharts
                                  :key="chartType + '_' + viewLevel + '_answers_split'"
                                  :options="getAnswerSplitOptions({ entries: [q] }, i, q[`name${lang.value}`], true)"
                                />
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card v-if="demographiesCount && demographiesCount.length > 0" class="mb-5" elevation="2">
            <v-card-title>Demographies count</v-card-title>
            <v-card-subtitle>Responded split between demographies (amount of answerers)</v-card-subtitle>
            <v-divider />
            <template v-for="result in demographiesCount">
              <v-card-text :key="result.question.id">
                <v-card-subtitle>{{ result.question[`name${lang.value}`] }}</v-card-subtitle>
                <v-simple-table>
                  <tbody>
                    <tr v-for="demo in result.demography" :key="demo.id">
                      <th style="width: 300px; white-space: nowrap">{{ demo[`name${lang.value}`] }}</th>
                      <td>{{ demo.answerers && demo.answerers[0] }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
              <v-divider :key="`divider_${result.question.id}`" />
            </template>
          </v-card>

          <v-card v-if="demographyQuestions && demographyQuestions.length > 0" elevation="2">
            <v-card-title>Responded split between demographies</v-card-title>
            <v-divider />
            <v-card-text>
              <template v-for="question in demographyQuestions">
                <v-list-group :key="question.id" :value="false" @click="getAnswersByDemography(question)">
                  <template v-slot:activator>
                    <v-list-item-title>{{ question[`name${lang.value}`] }}</v-list-item-title>
                  </template>

                  <div class="mb-5 mt-2">
                    <template v-if="demographyAnswers && demographyAnswers[question.id]">
                      <v-card v-for="stage in Object.keys(demographyAnswers[question.id])" :key="stage" class="mb-5" elevation="2">
                        <v-card-title>{{ stage.split('|')[lang.order] }}</v-card-title>
                        <v-card-text>
                          <v-card
                            v-for="level in Object.keys(demographyAnswers[question.id][stage])"
                            :key="level"
                            class="mb-5"
                            elevation="2"
                          >
                            <v-card-subtitle>{{ level }}</v-card-subtitle>
                            <v-card-text>
                              <v-simple-table>
                                <thead>
                                  <tr v-for="(demo, index) in [demographyAnswers[question.id][stage][level][0]]" :key="index">
                                    <th></th>
                                    <th
                                      v-for="(dimension, i) in demo.results"
                                      :key="dimension[`byName${lang.value}_${i}`]"
                                      :style="{
                                        whiteSpace: 'nowrap',
                                        color: pickTextColorBasedOnBgColorAdvanced(
                                          getHeaderBackgroundColor(dimension.areaEn),
                                          '#ffffff',
                                          '#000000'
                                        ),
                                        backgroundColor: getHeaderBackgroundColor(dimension.areaEn),
                                      }"
                                    >
                                      {{ dimension[`byName${lang.value}`] }}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="demo in demographyAnswers[question.id][stage][level]" :key="demo.demography.id">
                                    <th>{{ demo.demography[`name${lang.value}`] }}</th>
                                    <td
                                      v-for="dimension in demo.results"
                                      :key="dimension.id"
                                      :style="{
                                        whiteSpace: 'nowrap',
                                        color: pickTextColorBasedOnBgColorAdvanced(
                                          getHeaderBackgroundColor(dimension.areaEn),
                                          '#ffffff',
                                          '#000000'
                                        ),
                                        backgroundColor: getCellBackgroundColor(dimension.areaEn),
                                      }"
                                    >
                                      {{ formatPercentage(dimension.percentage) }} %
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </v-card-text>
                          </v-card>
                        </v-card-text>
                      </v-card>
                    </template>
                    <template v-else>
                      <loading-indicator />
                    </template>
                  </div>
                </v-list-group>
              </template>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in questionnaire.questions" :key="item.id"></tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="importSuccessful">
        {{ $t('message.import.successful') }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="importSuccessful = false"> Close</v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </hb-basic-page>
</template>

<script>
import questionnaireApi from '@/api/questionnaire';
import numeral from 'numeral';
import langFile from '@/config/lang';
import 'numeral/locales/fi';
import orderBy from 'lodash.orderby';

import LoadingIndicator from '@/components/LoadingIndicator';
import StarChart from '@/components/StarChart';
import Highcharts from 'highcharts';
import ChangeChart from '@/components/ChangeChart';
import combined from '@/plugins/combined';

export default {
  name: 'QuestionnaireAnswersView',
  components: { ChangeChart, StarChart, LoadingIndicator },
  data() {
    return {
      loading: true,
      questionnaire: {
        name: '',
        nameFi: null,
        nameSv: null,
        nameEn: 'New questionnaire',
        descriptionFi: null,
        descriptionSv: null,
        descriptionEn: null,
        orderer: null,
      },
      questionnaires: [],
      demographyAnswers: {},
      importSuccessful: false,
      colorHash: null,
      colorHashForText: null,
      demographiesCount: {},
      reportData: [],
      selectedQuestionnaire: null,
      selectedArea: null,
      selectedDimension: null,
      selectedDemography: null,
      viewLevel: 'dimension',
      chartType: 'bar',
      reportDataLoading: false,
      topEntries: null,
      selectedAnswerers: [],
      lang: {
        value: 'En',
        flag: 'us',
        order: 0,
      },
      unitType: 'percentage',
      langs: [
        {
          value: 'En',
          flag: 'us',
          label: 'English',
          order: 0,
        },
        {
          value: 'Sv',
          flag: 'se',
          label: 'Svenska',
          order: 2,
        },
        {
          value: 'Fi',
          flag: 'fi',
          label: 'Suomi',
          order: 1,
        },
      ],
    };
  },

  methods: {
    async getQuestionnaires() {
      this.loading = true;
      this.questionnaires = await questionnaireApi.getQuestionnaires();
      //console.log(this.filteredQuestionnaires);
      /*if (this.filteredQuestionnaires.length === 1 && this.selectedQuestionnaire === null) {
        this.selectedQuestionnaire = this.filteredQuestionnaires[0];
      }*/
      this.loading = false;
    },

    async getQuestionnaire() {
      this.loading = true;
      const questionnaire = await questionnaireApi.getQuestionnaire(this.$route.params.id);
      questionnaire.name = questionnaire[`name${this.lang.value}`] + ' (' + this.$formatDateNoTime(questionnaire.createdAt) + ')';
      this.questionnaire = questionnaire;
      this.loading = false;
    },
    async getAnswersByDemography(question) {
      const answers = await questionnaireApi.answerReportByDemography(this.$route.params.id, question.id, this.$store.state.testers);

      const result = {};
      const stages = this.getStages(answers);
      const levels = this.getLevels(answers);
      stages.forEach(stage => {
        result[stage] = {};
        levels.forEach(level => {
          result[stage][level] = [];
          answers.forEach(a => {
            result[stage][level].push({ demography: a.demography, results: a.stages[stage][level] });
          });
        });
      });

      this.demographyAnswers = { ...this.demographyAnswers, [question.id]: result };
    },
    async getDemographiesCount() {
      this.demographiesCount = await questionnaireApi.demographiesCount(
        [this.$route.params.id, this.selectedQuestionnaire?.id].filter(Boolean),
        this.$store.state.testers
      );
    },

    async getReportData() {
      this.reportDataLoading = true;
      this.reportData = await questionnaireApi.reportData(
        [this.$route.params.id, this.selectedQuestionnaire?.id].filter(Boolean),
        this.selectedDemography?.id,
        this.$store.state.testers,
        this.selectedAnswerers.map(s => s.id)
      );

      this.reportDataLoading = false;
      //this.reportData = JSON.parse('');
    },

    getStages(demoObject) {
      return Object.keys(demoObject[0]?.stages || {});
    },
    getLevels(demoObject) {
      return Object.keys(demoObject?.[0]?.stages?.[this.getStages(demoObject)[0]] || {});
    },
    toggleEdit() {
      this.edit = !this.edit;
    },
    async reloadAll() {
      await this.getQuestionnaire(this.$route.params.id);
      await this.getQuestionnaires();
      this.getDemographiesCount();
      this.getReportData();
    },
    formatPercentage(value) {
      return numeral(value).format('0.[0]');
    },
    getHeaderBackgroundColor(area, shade = 0) {
      let color = this.areaColors[this.areaMap(area)?.toUpperCase()]?.header;
      /*if (typeof area === 'number') {
        color = Object.values(this.areaColors)[area % 8].header;
      }*/
      return this.shadeColor(color, shade);
    },
    getCellBackgroundColor(area, shade = 0) {
      //return "#" + Math.floor(Math.random()*16777215).toString(16);
      return this.shadeColor(this.areaColors[this.areaMap(area)?.toUpperCase()]?.cell, shade);
    },
    shadeColor(color, percent) {
      if (!color) return null;
      let R = parseInt(color.substring(1, 3), 16);
      let G = parseInt(color.substring(3, 5), 16);
      let B = parseInt(color.substring(5, 7), 16);

      R = parseInt((R * (100 + percent)) / 100);
      G = parseInt((G * (100 + percent)) / 100);
      B = parseInt((B * (100 + percent)) / 100);

      R = R < 255 ? R : 255;
      G = G < 255 ? G : 255;
      B = B < 255 ? B : 255;

      let RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
      let GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
      let BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

      return '#' + RR + GG + BB;
    },
    areaMap(area) {
      switch (area) {
        case 'GÖR BÄST':
          return 'DO BEST';
        case 'GÖR FÖRST':
          return 'DO FIRST';
        case 'GÖR KLOKT':
          return 'DO WISELY';
        case 'GÖR MEST':
          return 'DO MOST';
        case 'GÖR RÄTT':
          return 'DO RIGHT';
        default:
          return area;
      }
    },
    /*modelMe(entries) {
      let component = this;
      return function () {
        let chart = this;
        //plotting starts from top-left clockwise to bottom-right
        let colors = entries.map(e => component.getCellBackgroundColor(e.area));

        let parts = entries.length;

        for (let i = 0; i < parts; i++) {
          chart.renderer
            .arc(
              chart.plotLeft + chart.yAxis[0].center[0],
              chart.plotTop + chart.yAxis[0].center[1],
              chart.yAxis[0].height,
              0,
              -Math.PI + (Math.PI / (parts / 2)) * i - 4.5,
              -Math.PI + (Math.PI / (parts / 2)) * (i + 1) - 4.5
            )
            .attr({
              fill: colors[i % colors.length],
              'stroke-width': 0,
              opacity: 1,
            })
            .add();
        }
      };
    },*/
    generatePolarSerie(entries, index, colors = ['#f19f67', '#ffc000']) {
      const that = this;
      return {
        type: 'line',
        name: this.reportData.questionnaires[index]?.[`name${this.lang.value}`],
        marker: {
          enable: true,
          symbol: 'circle',
        },
        //color: colors[index],
        dataLabels: {
          style: {
            fontSize: '16px',
          },
          enabled: true,
          formatter: function () {
            return that.unitType === 'average' ? to2Dec(this.y) : to0Dec(this.y) + ' %';
          },
        },
        data: entries.map(e => {
          return {
            color: colors[index] || null,
            y: e.properties?.includes('SELECT_TOP_X')
              ? to2Dec(e.points[index])
              : this.unitType === 'average'
              ? to2Dec(e.average[index])
              : to0Dec(e.percentage[index]),
            name: e[`name${this.lang.value}`],
          };
        }),
      };
    },
    generateBarSerie(entries, index, colors = ['#f19f67', '#ffc000'], type = 'line') {
      const that = this;
      return {
        type: type,
        name: this.reportData.questionnaires[index]?.[`name${this.lang.value}`],
        marker: {
          enable: true,
          symbol: 'circle',
        },
        color: colors[index] || '#003789',
        dataLabels: {
          style: {
            fontSize: '16px',
          },
          enabled: true,
          formatter: function () {
            return that.unitType === 'average' ? to2Dec(this.y) : to0Dec(this.y) + ' %';
          },
        },
        data: entries.map((e, i) => {
          return {
            color: colors[i] || '#003789',
            y: e.properties?.includes('SELECT_TOP_X')
              ? to2Dec(e.points[index])
              : this.unitType === 'average'
              ? to2Dec(e.average[index])
              : to0Dec(e.percentage[index]),
            name: e[`name${this.lang.value}`],
          };
        }),
      };
    },
    generateStackedBarSerie(entries, index, colors = ['#f19f67', '#ffc000'], type = 'line') {
      const that = this;

      return {
        type: type,
        name: this.reportData.questionnaires[index]?.[`name${this.lang.value}`],
        marker: {
          enable: true,
          symbol: 'circle',
        },
        color: 'grey',
        tooltip: {
          pointFormatter: function () {
            return `<span style='color:${this.color}'>\u25CF</span> ${this.series.name}: <b>${to2Dec(this.realY)} %</b><br/>`;
          },
        },
        dataLabels: {
          style: {
            fontSize: '16px',
          },
          enabled: true,
          formatter: function () {
            return that.unitType === 'average'
              ? to2Dec(this.point.realY)
              : `${to0Dec(this.point.realY)} % ${this.point.entryIndex === 0 ? `(${this.point.relativeY} %)` : ''}`;
          },
        },

        data: entries.map((e, i) => {
          let y = e.properties?.includes('SELECT_TOP_X')
            ? to2Dec(e.points[index])
            : this.unitType === 'average'
            ? to2Dec(e.average[index])
            : to0Dec(e.percentage[index]);
          let relativeY = y;
          let rel = 0;
          let color = colors[i] || '#003789';

          if (index === 0) {
            relativeY = e.properties?.includes('SELECT_TOP_X')
              ? to2Dec(e.points[index] - e.points[index + 1])
              : this.unitType === 'average'
              ? to2Dec(e.average[index] - e.average[index + 1])
              : to0Dec(e.percentage[index] - e.percentage[index + 1]);
            if (isNaN(relativeY)) {
              relativeY = 0;
            }
          }

          const realY = y;
          return {
            color: color,
            entryIndex: index,
            y: y,
            realY: realY,
            relativeY: relativeY,
            name: e[`name${this.lang.value}`],
            class: rel > 0 ? 'positive' : rel < 0 ? 'negative' : '',
            label: that.unitType === 'average' ? to2Dec(realY) : `${to0Dec(realY)} % ${index === 0 ? `(${relativeY} %)` : ''}`,
            tooltip: function () {
              return `<span style='color:${color}'>\u25CF</span> ${
                this.reportData.questionnaires[index]?.[`name${this.lang.value}`]
              }: <b>${to2Dec(realY)} %</b><br/>`;
            },
          };
        }),
      };
    },
    generateDemographyBarSerie(entries, index, demographies, colors = ['#f19f67', '#ffc000'], type = 'line') {
      const that = this;
      return {
        type: type,
        name: demographies?.[index]?.[`name${this.lang.value}`],
        marker: {
          enable: true,
          symbol: 'circle',
        },
        groupPadding: 0.1,
        color: this.getHeaderBackgroundColor(entries?.[0]?.areaEn, index * 25) || this.shadeColor('#003789', index * 25),
        dataLabels: {
          style: {
            fontSize: '16px',
          },
          enabled: true,
          formatter: function () {
            return that.unitType === 'average' ? to2Dec(this.y) : to0Dec(this.y) + ' %';
          },
        },

        data: entries.map((e, i) => {
          return {
            color: colors?.[i] || this.shadeColor('#003789', index * 25),
            y: e.properties?.includes('SELECT_TOP_X')
              ? to2Dec(e.points[index])
              : this.unitType === 'average'
              ? to2Dec(e.average[index])
              : to0Dec(e.percentage[index]),
            name: demographies?.[index]?.[`name${this.lang.value}`],
          };
        }),
      };
    },
    generateDemographyStackedBarSerie(entries, index, demographies, colors = ['#f19f67', '#ffc000'], type = 'line') {
      const that = this;

      return {
        type: type,
        name: demographies?.[index]?.[`name${this.lang.value}`],
        marker: {
          enable: true,
          symbol: 'circle',
        },
        color: this.getHeaderBackgroundColor(entries?.[0]?.areaEn, index * 25) || this.shadeColor('#003789', index * 25),
        tooltip: {
          pointFormatter: function () {
            return `<span style='color:${this.color}'>\u25CF</span> ${this.series.name}: <b>${to2Dec(this.realY)} %</b><br/>`;
          },
        },
        dataLabels: {
          style: {
            fontSize: '16px',
          },
          enabled: true,
          formatter: function () {
            return that.unitType === 'average'
              ? to2Dec(this.point.realY)
              : `${to0Dec(this.point.realY)} % ${this.point.entryIndex === 0 ? `(${this.point.relativeY} %)` : ''}`;
          },
        },

        data: entries.map((e, i) => {
          let y = e.properties?.includes('SELECT_TOP_X')
            ? to2Dec(e.points[index])
            : this.unitType === 'average'
            ? to2Dec(e.average[index])
            : to0Dec(e.percentage[index]);
          let relativeY = y;
          let rel = 0;
          let color = colors[i] || '#003789';

          if (index === 0) {
            relativeY = e.properties?.includes('SELECT_TOP_X')
              ? to2Dec(e.points[index] - e.points[index + 1])
              : this.unitType === 'average'
              ? to2Dec(e.average[index] - e.average[index + 1])
              : to0Dec(e.percentage[index] - e.percentage[index + 1]);
            if (isNaN(relativeY)) {
              relativeY = 0;
            }
          }
          const realY = y;
          return {
            color: color,
            entryIndex: index,
            y: y,
            realY: realY,
            relativeY: relativeY,
            name: demographies?.[index]?.[`name${this.lang.value}`],
            class: rel > 0 ? 'positive' : rel < 0 ? 'negative' : '',
            label: this.unitType === 'average' ? to2Dec(realY) : `${to0Dec(realY)} % ${index === 0 ? `(${relativeY} %)` : ''}`,
          };
        }),
      };
    },
    generateDemographyStackedBarSerieReversed(entries, index, demographies, colors = ['#f19f67', '#ffc000'], type = 'line') {
      const that = this;
      return {
        type: type,
        name: this.reportData.questionnaires[index]?.[`name${this.lang.value}`],
        marker: {
          enable: true,
          symbol: 'circle',
        },
        color: colors[index],
        tooltip: {
          pointFormatter: function () {
            return `<span style='color:${this.color}'>\u25CF</span> ${this.series.name}: <b>${to2Dec(this.realY)} %</b><br/>`;
          },
        },
        dataLabels: {
          style: {
            fontSize: '16px',
          },
          enabled: true,
          formatter: function () {
            return that.unitType === 'average'
              ? to2Dec(this.point.realY)
              : `${to0Dec(this.point.realY)} % ${this.point.entryIndex === 0 ? `(${this.point.relativeY} %)` : ''}`;
          },
        },

        data: entries.map(e => {
          let y = e.properties?.includes('SELECT_TOP_X')
            ? to2Dec(e.points[index])
            : this.unitType === 'average'
            ? to2Dec(e.average[index])
            : to0Dec(e.percentage[index]);
          let relativeY = y;
          let rel = 0;
          let color = colors[index] || '#003789';

          if (index === 0) {
            relativeY = e.properties?.includes('SELECT_TOP_X')
              ? to2Dec(e.points[index] - e.points[index + 1])
              : this.unitType === 'average'
              ? to2Dec(e.average[index] - e.average[index + 1])
              : to0Dec(e.percentage[index] - e.percentage[index + 1]);
            if (isNaN(relativeY)) {
              relativeY = 0;
            }
          }
          const realY = y;
          return {
            color: color,
            entryIndex: index,
            y: y,
            realY: realY,
            relativeY: relativeY,
            name: e[`name${this.lang.value}`],
            class: rel > 0 ? 'positive' : rel < 0 ? 'negative' : '',
            label: that.unitType === 'average' ? to2Dec(realY) : `${to0Dec(realY)} % ${index === 0 ? `(${relativeY} %)` : ''}`,
          };
        }),
      };
    },
    generateDemographyPolarSerie(entries, index, demographies, type = 'line') {
      const that = this;
      const color = ['#003f5c', '#f95d6a', '#665191', '#ff7c43', '#d45087', '#ffa600', '#a05195', '#2f4b7c'];
      return {
        type: type,
        name: demographies?.[index]?.[`name${this.lang.value}`],
        color: color[index % color.length],
        marker: {
          enable: true,
          symbol: ['circle', 'square', 'diamond', 'triangle', 'triangle-down'][index],
        },
        dataLabels: {
          style: {
            fontSize: '16px',
          },
          enabled: true,
          formatter: function () {
            return that.unitType === 'average' ? to2Dec(this.y) : to0Dec(this.y) + ' %';
          },
        },
        data: entries.map(e => {
          return {
            color: /*colors?.[i] ||*/ null,
            y: e.properties?.includes('SELECT_TOP_X')
              ? to2Dec(e.points[index])
              : this.unitType === 'average'
              ? to2Dec(e.average[index])
              : to0Dec(e.percentage[index]),
            name: demographies?.[index]?.[`name${this.lang.value}`],
          };
        }),
      };
    },
    generateHighestLowestSerie(entries, questionnaireIndex, colors = ['#f19f67', '#ffc000'], type = 'column') {
      const that = this;
      return {
        type: type,
        name: this.reportData.questionnaires[questionnaireIndex]?.[`name${this.lang.value}`],
        marker: {
          enable: true,
          symbol: 'circle',
        },
        color: colors[0],
        dataLabels: {
          style: {
            fontSize: '16px',
          },
          enabled: true,
          formatter: function () {
            return that.unitType === 'average' ? to2Dec(this.y) : to0Dec(this.y) + ' %';
          },
        },
        data: orderBy(
          entries.map((e, i) => {
            return {
              entry: e,
              color: colors[i],
              y: e.properties?.includes('SELECT_TOP_X')
                ? to2Dec(e.points[questionnaireIndex])
                : that.unitType === 'average'
                ? to2Dec(e.average[questionnaireIndex])
                : to0Dec(e.percentage[questionnaireIndex]),
              name: e[`name${this.lang.value}`],
            };
          }),
          ['y'],
          [this.chartType === 'highest' ? 'desc' : 'asc']
        ),
      };
    },
    generateGapSerie(entries, colors = ['#f19f67', '#ffc000'], type = 'column') {
      const that = this;
      const data = entries.map((e, i) => {
        const value = e.properties?.includes('SELECT_TOP_X')
          ? to2Dec(
              e.points.reduce((c, p, i) => {
                if (i > 0) return c - p;
                return c;
              }, e.points[0])
            )
          : this.unitType === 'average'
          ? to2Dec(
              e.average.reduce((c, p, i) => {
                if (i > 0) return c - p;
                return c;
              }, e.average[0])
            )
          : to0Dec(
              e.percentage.reduce((c, p, i) => {
                if (i > 0) return c - p;
                return c;
              }, e.percentage[0])
            );
        return {
          color: colors[i],
          y: value,
          name: e[`name${this.lang.value}`],
          absY: Math.abs(value),
        };
      });

      return {
        type: type,
        //name: this.reportData.questionnaires[index].nameEn,
        marker: {
          enable: true,
          symbol: 'circle',
        },
        //color: colors[index],
        dataLabels: {
          style: {
            fontSize: '16px',
          },
          enabled: true,
          formatter: function () {
            return that.unitType === 'average' ? to2Dec(this.y) : to0Dec(this.y) + ' %';
          },
        },
        data,
      };
    },

    getPolarChartOptions(answerData, index) {
      //https://stackoverflow.com/questions/25129119/highcharts-polar-chart-multiple-pane-segment-colors
      const entries = this.getTopEntries(answerData.entries);

      const count = entries?.[0]?.percentage?.length;
      const series = [...Array(count)].map((_, i) => {
        return this.generatePolarSerie(entries, i, index, 'column');
      });

      const options = {
        chart: {
          //spider: true,
          polar: true,
          type: 'line',
          width: 1000,
          height: 1000,
          plotBackgroundColor: null,
          /*events: {
            load: this.modelMe(entries),
          },*/
        },
        title: this.chartTitle,
        credits: {
          enabled: false,
        },
        subtitle: {
          text: '',
        },
        legend: {
          align: 'top',
          verticalAlign: 'top',
          layout: 'horizontal',
          y: 50,
          itemStyle: {
            fontSize: '16px',
          },
        },
        pane: {
          startAngle: 0,
        },

        xAxis: {
          categories: entries.map(e => e[`name${this.lang.value}`]),
          tickmarkPlacement: 'on',
          gridLineWidth: 1,
          labels: {
            style: {
              color: '#000000',
              fontSize: '16px',
            },
          },
          plotBands: entries.flatMap((e, i) => {
            return [
              {
                innerRadius: 0,
                from: i - 0.5,
                to: i + 0.5,
                color: this.getCellBackgroundColor(e.areaEn),
                borderWidth: 1,
                borderColor: this.getCellBackgroundColor(e.areaEn),
                thickness: 0,
              },
              entries.length - 1 === i && {
                innerRadius: 0,
                from: i + 0.5,
                to: i + 1,
                color: this.getCellBackgroundColor(entries[0].areaEn),
                borderWidth: 1,
                borderColor: this.getCellBackgroundColor(entries[0].areaEn),
                thickness: 0,
              },
            ].filter(Boolean);
          }),
        },

        yAxis: {
          start: 0,
          angle: 0,
          alignTicks: false,
          tickInterval: this.unitType === 'average' ? 0.5 : 10,
          softMax: this.getYMax(entries),
          softMin: 0,
          gridLineInterpolation: 'circle',
          lineWidth: 0,
          min: 0,
          opposite: true,
          panningEnabled: false,
          startOnTick: false,

          labels: {
            style: {
              color: '#000000',
              fontSize: '16px',
            },
          },

          // offset: -50
        },
        series: series,
        tooltip: {
          /*pointFormatter: function(){
            console.log(this);
            return `<span style="color:${this.color}"></span> ${this.series.name}: <b>${parseInt(this.y)} %</b><br/>`
          }*/
          shared: true,
          pointFormat: `<span style='color:{series.color}'>{series.name}: <b>{point.y:,.2f} ${
            this.unitType === 'average' ? '' : '%'
          }</b><br/>`,
        },
        plotOptions: {
          column: {
            pointWidth: 18,
            pointPadding: 0,
            groupPadding: 0,
          },
        },
      };
      return options;
    },
    getBarChartOptions(answerData) {
      //https://stackoverflow.com/questions/25129119/highcharts-polar-chart-multiple-pane-segment-colors
      const entries = this.getTopEntries(answerData.entries);

      const count = entries?.[0]?.percentage?.length;

      const series = [...Array(count)].map((_, i) => {
        return this.generateBarSerie(
          entries,
          i,
          entries.map(e => {
            return this.getHeaderBackgroundColor(e.areaEn, (i - 1) * 25);
          }),
          'column'
        );
      });
      const options = {
        chart: {
          type: 'column',
          inverted: true,
          polar: false,
          width: this.viewLevel === 'question' ? 1300 : 1000,
          height: this.chartHeight(entries, series),
          plotBackgroundColor: null,
          marginTop: 100,
          marginRight: 20,
        },
        title: this.chartTitle,
        credits: {
          enabled: false,
        },
        subtitle: {
          text: '',
        },
        legend: {
          align: 'top',
          verticalAlign: 'top',
          layout: 'horizontal',
          y: 50,
          itemStyle: {
            fontSize: '16px',
          },
        },
        xAxis: {
          categories: entries.map(e => {
            if (this.viewLevel === 'question') {
              if (!e?.dimension?.[`name${this.lang.value}`]) {
                return `${e[`name${this.lang.value}`]}`;
              }
              // eslint-disable-next-line no-unused-vars
              const color = this.areaColors?.[e.areaEn?.toUpperCase()];

              // With this you can get the dimension name and color under the question name
              /*return `${e[`name${this.lang.value}`]}<br /><span style='font-size: 14px; color: ${color?.header}'>${
                e?.dimension?.[`name${this.lang.value}`]
              }</span>`;*/

              return `${e[`name${this.lang.value}`]}`;
            } else {
              return `${e[`name${this.lang.value}`]}`;
            }
          }),

          labels: {
            step: 1,
            style: {
              color: '#000000',
              width: this.viewLevel === 'question' ? '450px' : null,
              fontSize: this.viewLevel === 'question' ? '14px' : '16px',
              textOverflow: 'none',
            },
          },
        },

        yAxis: {
          min: 0,
          max: this.getYMax(entries),

          labels: {
            style: {
              color: '#000000',
              fontSize: this.viewLevel === 'question' ? '14px' : '16px',
            },
          },
        },
        series: series,
        tooltip: {
          /*pointFormatter: function(){
            console.log(this);
            return `<span style="color:${this.color}"></span> ${this.series.name}: <b>${parseInt(this.y)} %</b><br/>`
          }*/
          shared: true,
          pointFormat: `<span>{series.name}: <b>{point.y:,.2f} ${this.unitType === 'average' ? '' : '%'}</b><br/>`,
        },
        plotOptions: {
          column: {
            borderRadiusTopLeft: '50%',
            borderRadiusTopRight: '50%',
            borderRadiusBottomLeft: '50%',
            borderRadiusBottomRight: '50%',
            pointWidth: 18,
          },
        },
        /*plotOptions: {
          series: {
            colorByPoint: true,
          },
        },*/
      };

      return options;
    },
    getAnswerSplitOptions(answerData, index, title, combined = false) {
      let splits = {};
      let scale, min, max;

      const data = [...answerData.entries];

      function numberRange(start, end) {
        return new Array(end - start).fill().map((d, i) => i + start);
      }

      for (let e of data) {
        if (e.question?.answerType?.startsWith('OPINION_SCALE,')) {
          scale = e.question?.answerType.split(',')?.[1]?.split('-');
          min = parseInt(scale[0]);
          if (min === 0) {
            min = 1;
          }
          max = parseInt(scale[1]);

          let numbers = numberRange(min, max + 1);
          numbers.forEach(n => {
            splits[n] = 0;
          });
          break;
        }
        if (e.question?.answerType?.startsWith('DESCRIBING,')) {
          scale = e.question?.answerType.split(',')?.[1]?.split('|');
          /*console.log(scale);
          min = parseInt(scale[0]);
          if (min === 0) {
            min = 1;
          }
          max = parseInt(scale[scale.length - 1]);*/
          scale.forEach(n => {
            splits[n] = 0;
          });
          break;
        }
      }

      data.forEach(e => {
        if (e.question?.answerType?.startsWith('OPINION_SCALE,')) {
          if (e.values) {
            e.values.forEach(v => {
              if (!splits[v]) {
                splits[v] = 0;
              }
              splits[v]++;
            });
          }
        } else if (e.question?.answerType?.startsWith('DESCRIBING,')) {
          if (e.values) {
            e.values.forEach(v => {
              if (!splits[v]) {
                splits[v] = 0;
              }
              splits[v]++;
            });
          }
        }
      });

      /*if (combined) {
        let list = {};
        let entryKey = null;
        data?.map(e => {
          const key = entryKey ? e[entryKey] : e.nameEn || e.order;
          list[key] = [...(list[key] || []), e];
        });
        console.log(list);
        let combinedSplits = {};
        for (let key in splits) {
          combinedSplits[key] = 0;
        }

        let numbers = numberRange(0, max + 1);
        for (let e of data) {
          if (e.question?.answerType?.startsWith('OPINION_SCALE,')) {
            if (e.values) {
              e.values.forEach(v => {
                combinedSplits[v]++;
              });
            }
          } else if (e.question?.answerType?.startsWith('DESCRIBING,')) {
            if (e.values) {
              e.values.forEach(v => {
                combinedSplits[v]++;
              });
            }
          }
        }
        splits = combinedSplits;
      }*/

      function convertToPercentages(obj) {
        let total = 0;
        // Calculate the total sum of values
        for (let key in obj) {
          total += obj[key];
        }

        // Convert each value to a percentage of the total
        let percentages = {};
        for (let key in obj) {
          percentages[key] = parseFloat(((obj[key] / total) * 100).toFixed(2));
        }

        return percentages;
      }

      if (this.unitType === 'percentage') {
        splits = convertToPercentages(splits);
      }
      let colors = ['#FF0000', '#ff6334', '#d9844c', '#f1d071', '#aed396', '#3b8e80'];
      if (max === 5) {
        colors = ['#FF0000', '#ff6334', '#f1d071', '#aed396', '#3b8e80'];
      } else if (max === 4) {
        colors = ['#FF0000', '#ff6334', '#f1d071', '#3b8e80'];
      } else if (max === 3) {
        colors = ['#FF0000', '#f1d071', '#3b8e80'];
      }

      const options = {
        chart: {
          type: 'column',
          polar: false,
          width: this.viewLevel === 'question' ? 550 : 550,
          height: 400,
          plotBackgroundColor: null,
          marginTop: 100,
          marginRight: 20,
        },
        title: {
          text: title || '',
        },
        subtitle: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: Object.keys(splits),
          labels: {
            step: 1,
            style: {
              color: '#000000',
              width: this.viewLevel === 'question' ? '450px' : null,
              fontSize: this.viewLevel === 'question' ? '14px' : '16px',
              textOverflow: 'none',
            },
          },
        },
        yAxis: {
          min: 0,
          max: this.unitType === 'percentage' ? 100 : null,
          labels: {
            format: `{text} ${this.unitType === 'percentage' ? '%' : ''}`,
            style: {
              color: '#000000',
              fontSize: this.viewLevel === 'question' ? '14px' : '16px',
            },
          },
          title: {
            text: '',
          },
        },

        series: [
          {
            data: Object.values(splits).map((v, i) => {
              return {
                y: v,
                color: colors[i],
              };
            }),
          },
        ],
        tooltip: {
          /*pointFormatter: function(){
            console.log(this);
            return `<span style="color:${this.color}"></span> ${this.series.name}: <b>${parseInt(this.y)} %</b><br/>`
          }*/
          shared: true,
          pointFormat: `<span><b>{point.y:,.0f} ${this.unitType === 'percentage' ? '%' : ''}</b><br/>`,
        },
        plotOptions: {
          column: {
            borderRadiusTopLeft: '50%',
            borderRadiusTopRight: '50%',
            borderRadiusBottomLeft: '50%',
            borderRadiusBottomRight: '50%',
            pointWidth: 18,
            dataLabels: {
              enabled: true,
              format: `{point.y:,.0f} ${this.unitType === 'percentage' ? '%' : ''}`,
            },
          },
        },
      };

      return options;
    },
    getStackedBarChartOptions(answerData) {
      //https://stackoverflow.com/questions/25129119/highcharts-polar-chart-multiple-pane-segment-colors
      const entries = this.getTopEntries(answerData.entries);
      const count = entries?.[0]?.percentage?.length;
      const series = [...Array(count)].map((_, i) => {
        return this.generateStackedBarSerie(
          entries,
          i,

          entries.map(e => {
            return this.getHeaderBackgroundColor(e.areaEn, (i - 1) * 15);
          }),
          'column'
        );
      });
      const options = {
        chart: {
          type: 'column',
          inverted: true,
          polar: false,
          width: 1000,
          height: this.chartHeight(entries),
          plotBackgroundColor: null,
          marginTop: 100,
          marginRight: 20,
        },
        title: this.chartTitle,
        credits: {
          enabled: false,
        },
        subtitle: {
          text: '',
        },
        legend: {
          align: 'top',
          verticalAlign: 'top',
          layout: 'horizontal',
          y: 50,
        },
        xAxis: {
          categories: entries.map(e => e[`name${this.lang.value}`]),
          labels: {
            style: {
              color: '#000000',
              fontSize: '16px',
            },
          },
        },

        yAxis: {
          min: 0,
          max: this.getYMax(entries),

          labels: {
            style: {
              color: '#000000',
              fontSize: '16px',
            },
          },
        },
        series: series,
        tooltip: {
          /*pointFormatter: function(){
            console.log(this);
            return `<span style="color:${this.color}"></span> ${this.series.name}: <b>${parseInt(this.y)} %</b><br/>`
          }*/
          shared: true,
          pointFormat: `<span>{series.name}: <b>{point.y:,.2f} ${this.unitType === 'average' ? '' : '%'}</b><br/>`,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
          },
          column: {
            maxPointWidth: 20,
            borderRadiusTopLeft: '50%',
            borderRadiusTopRight: '50%',
            borderRadiusBottomLeft: '50%',
            borderRadiusBottomRight: '50%',
          },
        },
      };

      return options;
    },
    getBarChartDemographyOptions(answerData) {
      //https://stackoverflow.com/questions/25129119/highcharts-polar-chart-multiple-pane-segment-colors
      const entries = this.getTopEntries(answerData.entries);

      //console.log('entries', entries);
      const count = entries?.[0]?.percentage?.length;

      const demographies = this.reportData?.demographies?.find(d => d.order === answerData.order)?.demographies || [];

      const series = [...Array(count)].map((_, i) => {
        return this.generateDemographyBarSerie(
          entries,
          i,
          demographies,
          /*entries.map(e => {
            return this.getHeaderBackgroundColor(e.areaEn || 'UNKNOWN', 0);
          }),*/
          entries.map(e => {
            return this.getHeaderBackgroundColor(e.areaEn, (i - 1) * 25);
          }),
          'column'
        );
      });
      const demographyLength = this.reportData?.demographies?.find(d => d.order === answerData.order)?.demographies?.length;
      const options = {
        chart: {
          type: 'column',
          inverted: true,
          polar: false,
          width: 1000,
          height: entries.length <= 4 ? 300 + 20 * entries.length : Math.max(1000, 300 + demographyLength * 20 * entries.length),
          plotBackgroundColor: null,
          marginTop: 100,
          marginRight: 20,
        },
        title: {
          text: this.reportData.questionnaires.find(q => q.id === answerData.questionnaireId)?.[`name${this.lang.value}`],
        },
        credits: {
          enabled: false,
        },
        subtitle: {
          text: this.selectedDemography[`name${this.lang.value}`],
        },
        legend: {
          align: 'top',
          verticalAlign: 'top',
          layout: 'horizontal',
          y: 50,
        },
        xAxis: {
          categories: entries.map(e => {
            if (this.viewLevel === 'question') {
              if (!e?.dimension?.[`name${this.lang.value}`]) {
                return `${e[`name${this.lang.value}`]}`;
              }
              const color = this.areaColors?.[e.areaEn?.toUpperCase()];

              return `${e[`name${this.lang.value}`]}<br /><span style='font-size: 14px; color: ${color?.header}'>${
                e?.dimension?.[`name${this.lang.value}`]
              }</span>`;
            } else {
              return `${e[`name${this.lang.value}`]}`;
            }
          }),
          labels: {
            style: {
              color: '#000000',
              fontSize: '16px',
              width: this.viewLevel === 'question' ? '450px' : null,
            },
          },
        },

        yAxis: {
          min: 0,
          max: this.getYMax(entries),

          labels: {
            style: {
              color: '#000000',
              fontSize: '16px',
            },
          },
        },
        series: series,
        tooltip: {
          /*pointFormatter: function(){
            console.log(this);
            return `<span style="color:${this.color}"></span> ${this.series.name}: <b>${parseInt(this.y)} %</b><br/>`
          }*/
          shared: true,
          headerFormat: `<span></span>`,
          pointFormat: `<span>{point.name}: <b>{point.y:,.2f} ${this.unitType === 'average' ? '' : '%'}</b><br/>`,
        },
        plotOptions: {
          column: {
            borderRadiusTopLeft: '50%',
            borderRadiusTopRight: '50%',
            borderRadiusBottomLeft: '50%',
            borderRadiusBottomRight: '50%',
            maxPointWidth: 20,
          },
        },
        /*plotOptions: {
          series: {
            colorByPoint: true,
          },
        },*/
      };

      return options;
    },
    getStackedBarChartDemographyOptions(answerData) {
      //https://stackoverflow.com/questions/25129119/highcharts-polar-chart-multiple-pane-segment-colors
      const entries = this.getTopEntries(answerData.entries);

      const count = entries?.[0]?.percentage?.length;

      const areaColors = {};
      const series = [...Array(count)].map((_, i) => {
        return this.generateDemographyStackedBarSerie(
          entries,
          i,
          this.reportData?.demographies?.find(d => d.order === answerData.order)?.demographies || [],
          entries.map(e => {
            const areaName = e.areaEn || 'UNKNOWN';
            if (!areaColors[areaName]) {
              areaColors[areaName] = 0;
            }
            const color = this.getHeaderBackgroundColor(areaName || 'UNKNOWN', (areaColors[areaName] || 0) * 5);

            areaColors[areaName] += 1.0;
            return color;
          }),
          'column'
        );
      });
      const options = {
        chart: {
          type: 'column',
          inverted: true,
          polar: false,
          width: 1000,
          height: Math.max(
            1000,
            this.reportData?.demographies?.find(d => d.order === answerData.order)?.demographies?.length * 20 * entries.length
          ),
          plotBackgroundColor: null,
          marginTop: 100,
          marginRight: 20,
        },
        title: {
          text: this.reportData.questionnaires.find(q => q.id === answerData.questionnaireId)?.[`name${this.lang.value}`],
        },
        credits: {
          enabled: false,
        },
        subtitle: {
          text: this.selectedDemography[`name${this.lang.value}`],
        },
        legend: {
          align: 'top',
          verticalAlign: 'top',
          layout: 'horizontal',
          y: 50,
        },
        xAxis: {
          categories: entries.map(e => e[`name${this.lang.value}`]),
          labels: {
            style: {
              color: '#000000',
              fontSize: '16px',
            },
          },
        },

        yAxis: {
          min: 0,
          max: this.getYMax(entries),
          labels: {
            style: {
              color: '#000000',
              fontSize: '16px',
            },
          },
        },
        series: series,
      };

      return options;
    },
    getStackedBarChartDemographyReversedOptions(answerData) {
      //https://stackoverflow.com/questions/25129119/highcharts-polar-chart-multiple-pane-segment-colors
      const entries = this.getTopEntries(answerData.entries);

      const count = entries?.[0]?.percentage?.length;
      const areaColors = {};
      const series = [...Array(count)].map((_, i) => {
        return this.generateDemographyStackedBarSerieReversed(
          entries,
          i,
          this.reportData?.demographies?.find(d => d.order === answerData.order)?.demographies || [],
          entries.map(e => {
            const areaName = e.areaEn || 'UNKNOWN';
            if (!areaColors[areaName]) {
              areaColors[areaName] = 0;
            }
            const color = this.getHeaderBackgroundColor(areaName || 'UNKNOWN', (areaColors[areaName] || 0) * 5);

            areaColors[areaName] += 1.0;
            return color;
          }),
          'column'
        );
      });

      const options = {
        chart: {
          type: 'column',
          inverted: true,
          polar: false,
          width: 1000,
          height: Math.max(
            1000,
            this.reportData?.demographies?.find(d => d.order === answerData.order)?.demographies?.length * 20 * entries.length
          ),
          plotBackgroundColor: null,
          marginTop: 100,
          marginRight: 20,
        },
        title: this.chartTitle,
        credits: {
          enabled: false,
        },
        subtitle: {
          text: this.selectedDemography[`name${this.lang.value}`] + ' - ' + answerData[`name${this.lang.value}`],
        },
        legend: {
          align: 'top',
          verticalAlign: 'top',
          layout: 'horizontal',
          y: 50,
        },
        xAxis: {
          categories: this.selectedDemography?.demographies?.map(e => e[`name${this.lang.value}`]),
          labels: {
            style: {
              color: '#000000',
              fontSize: '16px',
            },
          },
        },

        yAxis: {
          min: 0,
          max: this.getYMax(entries),
          labels: {
            style: {
              color: '#000000',
              fontSize: '16px',
            },
          },
        },
        series: series,
      };
      return options;
    },
    getPolarChartDemographyOptions(answerData) {
      //https://stackoverflow.com/questions/25129119/highcharts-polar-chart-multiple-pane-segment-colors
      const entries = this.getTopEntries(answerData.entries);

      const count = entries?.[0]?.percentage?.length;

      const series = [...Array(count)].map((_, i) => {
        return this.generateDemographyPolarSerie(
          entries,
          i,
          this.reportData?.demographies?.find(d => d.order === answerData.order)?.demographies || [],
          /*entries.map(e => {
            return this.getHeaderBackgroundColor(e.areaEn || 'UNKNOWN', 0);
          }),*/
          'line'
        );
      });

      const options = {
        chart: {
          //spider: true,
          polar: true,
          type: 'line',
          width: 1000,
          height: 1000,
          plotBackgroundColor: null,
          /*events: {
            load: this.modelMe(entries),
          },*/
        },
        title: { text: this.reportData.questionnaires.find(q => q.id === answerData.questionnaireId)?.[`name${this.lang.value}`] },
        credits: {
          enabled: false,
        },
        subtitle: {
          text: this.selectedDemography[`name${this.lang.value}`],
        },
        legend: {
          align: 'top',
          verticalAlign: 'top',
          layout: 'horizontal',
          y: 50,
        },
        pane: {
          startAngle: 0,
        },

        xAxis: {
          categories: entries.map(e => e[`name${this.lang.value}`]),
          tickmarkPlacement: 'on',
          gridLineWidth: 1,
          labels: {
            style: {
              fontSize: '16px',
            },
          },
          plotBands: entries.flatMap((e, i) => {
            return [
              {
                innerRadius: 0,
                from: i - 0.5,
                to: i + 0.5,
                color: this.getCellBackgroundColor(e.areaEn),
                borderWidth: 1,
                borderColor: this.getCellBackgroundColor(e.areaEn),
                thickness: 0,
              },
              entries.length - 1 === i && {
                innerRadius: 0,
                from: i + 0.5,
                to: i + 1,
                color: this.getCellBackgroundColor(entries[0].areaEn),
                borderWidth: 1,
                borderColor: this.getCellBackgroundColor(entries[0].areaEn),
                thickness: 0,
              },
            ].filter(Boolean);
          }),
        },

        yAxis: {
          start: 0,
          angle: 0,
          alignTicks: false,
          tickInterval: this.unitType === 'average' ? 0.5 : 10,
          softMax: this.getYMax(entries),
          softMin: 0,
          gridLineInterpolation: 'circle',
          lineWidth: 0,
          min: 0,
          opposite: true,
          panningEnabled: false,
          startOnTick: false,

          labels: {
            style: {
              fontSize: '16px',
            },
          },

          // offset: -50
        },
        series: series,
        tooltip: {
          /*pointFormatter: function(){
            console.log(this);
            return `<span style="color:${this.color}"></span> ${this.series.name}: <b>${parseInt(this.y)} %</b><br/>`
          }*/
          shared: true,
          pointFormat: `<span style='color:{series.color}'>{series.name}: <b>{point.y:,.2f} ${
            this.unitType === 'average' ? '' : '%'
          }</b><br/>`,
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            groupPadding: 0,
            borderRadiusTopLeft: '50%',
            borderRadiusTopRight: '50%',
            borderRadiusBottomLeft: '50%',
            borderRadiusBottomRight: '50%',
          },
        },
      };
      return options;
    },
    getHighestLowestChartOptions(answerData, index, questionnaireIndex) {
      //https://stackoverflow.com/questions/25129119/highcharts-polar-chart-multiple-pane-segment-colors
      const entries = answerData.entries;

      const series = [
        this.generateHighestLowestSerie(
          entries,
          questionnaireIndex,
          entries.map(e => {
            return this.getHeaderBackgroundColor(e.areaEn, (0 - 1) * 25);
          }),
          'column'
        ),
      ];
      if (series[0] && series[0].data) {
        series[0].data = this.getTopEntries(series[0].data);
      }

      const options = {
        chart: {
          type: 'column',
          inverted: true,
          polar: false,
          width: this.viewLevel === 'question' ? 1300 : 1000,
          height: this.chartHeight(entries),
          plotBackgroundColor: null,
          marginTop: 100,
          marginRight: 20,
        },
        title: {
          text:
            (this.chartType === 'highest'
              ? `${langFile[this.lang.value.toLowerCase()]['highest']}: `
              : `${langFile[this.lang.value.toLowerCase()]['lowest']}: `) +
            this.reportData.questionnaires[questionnaireIndex][`name${this.lang.value}`],
        },
        credits: {
          enabled: false,
        },
        subtitle: {
          text: '',
        },
        xAxis: {
          categories: series[0]?.data?.map(e => {
            if (this.viewLevel === 'question') {
              if (!e?.entry?.dimension?.[`name${this.lang.value}`]) {
                return `${e.entry?.[`name${this.lang.value}`]}`;
              }
              // eslint-disable-next-line no-unused-vars
              const color = this.areaColors?.[e?.entry?.areaEn?.toUpperCase()];

              /*return `${e[`name${this.lang.value}`]}<br /><span style='font-size: 14px; color: ${color?.header}'>${
                e?.dimension?.[`name${this.lang.value}`]
              }</span>`;*/
              return `${e.entry?.[`name${this.lang.value}`]}`;
            } else {
              return `${e.entry?.[`name${this.lang.value}`]}`;
            }
          }),

          labels: {
            style: {
              color: '#000000',
              fontSize: this.viewLevel === 'question' ? '14px' : '16px',
              textOverflow: 'none',
              width: this.viewLevel === 'question' ? '450px' : null,
            },
          },
        },

        yAxis: {
          min: 0,
          max: this.getYMax(entries),

          labels: {
            style: {
              color: '#000000',
              fontSize: this.viewLevel === 'question' ? '14px' : '16px',
            },
          },
        },
        series: series,
        tooltip: {
          /*pointFormatter: function(){
            console.log(this);
            return `<span style="color:${this.color}"></span> ${this.series.name}: <b>${parseInt(this.y)} %</b><br/>`
          }*/
          shared: true,
          pointFormat: `<span>{series.name}: <b>{point.y:,.2f} ${this.unitType === 'average' ? '' : '%'}</b><br/>`,
        },
        plotOptions: {
          column: {
            pointWidth: 18,
            borderRadiusTopLeft: '50%',
            borderRadiusTopRight: '50%',
            borderRadiusBottomLeft: '50%',
            borderRadiusBottomRight: '50%',
          },
        },
        /*plotOptions: {
          series: {
            colorByPoint: true,
          },
        },*/
      };
      return options;
    },
    getGapChartOptions(answerData) {
      //https://stackoverflow.com/questions/25129119/highcharts-polar-chart-multiple-pane-segment-colors

      const entries = this.getTopEntries(
        orderBy(
          answerData.entries.map(e => {
            const value =
              this.unitType === 'average'
                ? to2Dec(
                    e.average.reduce((c, p, i) => {
                      if (i > 0) return c - p;
                      return c;
                    }, e.average[0])
                  )
                : to0Dec(
                    e.percentage.reduce((c, p, i) => {
                      if (i > 0) return c - p;
                      return c;
                    }, e.percentage[0])
                  );
            e.absY = Math.abs(value);
            return e;
          }),
          ['absY'],
          ['desc']
        )
      );

      const series = [
        this.generateGapSerie(
          entries,
          entries.map(e => {
            return this.getHeaderBackgroundColor(e.areaEn, (0 - 1) * 25);
          })
        ),
      ];

      const options = {
        chart: {
          type: 'column',
          inverted: true,
          polar: false,
          width: this.viewLevel === 'question' ? 1300 : 1000,
          height: this.chartHeight(entries),
          plotBackgroundColor: null,
          marginTop: 100,
          marginRight: 20,
        },
        title: {
          ...this.chartTitle,
          text: `${langFile[this.lang.value.toLowerCase()]['gap']}: ` + this.chartTitle.text,
        },
        credits: {
          enabled: false,
        },
        subtitle: {
          text: '',
        },
        xAxis: {
          categories: entries.map(e => {
            if (this.viewLevel === 'question') {
              if (!e?.dimension?.[`name${this.lang.value}`]) {
                return `${e[`name${this.lang.value}`]}`;
              }
              // eslint-disable-next-line no-unused-vars
              const color = this.areaColors?.[e.areaEn?.toUpperCase()];

              /*
              return `${e[`name${this.lang.value}`]}<br /><span style='font-size: 14px; color: ${color?.header}'>${
                e?.dimension?.[`name${this.lang.value}`]
              }</span>`;*/
              return `${e[`name${this.lang.value}`]}`;
            } else {
              return `${e[`name${this.lang.value}`]}`;
            }
          }),
          labels: {
            style: {
              color: '#000000',
              fontSize: this.viewLevel === 'question' ? '14px' : '16px',
              textOverflow: 'none',
              width: this.viewLevel === 'question' ? '450px' : null,
            },
          },
        },

        yAxis: {
          labels: {
            style: {
              color: '#000000',
              fontSize: this.viewLevel === 'question' ? '14px' : '16px',
            },
          },
        },
        series: series,
        tooltip: {
          shared: true,
          pointFormat: `<span>{series.name}: <b>{point.y:,.2f} ${this.unitType === 'average' ? '' : '%'}</b><br/>`,
        },
        plotOptions: {
          column: {
            pointWidth: 18,
            borderRadiusTopLeft: '50%',
            borderRadiusTopRight: '50%',
            borderRadiusBottomLeft: '50%',
            borderRadiusBottomRight: '50%',
          },
        },
        /*plotOptions: {
          series: {
            colorByPoint: true,
          },
        },*/
      };
      return options;
    },

    getBarChartDemographySplitOptions(answerData) {
      //https://stackoverflow.com/questions/25129119/highcharts-polar-chart-multiple-pane-segment-colors
      const answer = answerData.find(a => a.question?.id === this.selectedDemography.id);
      if (!answer) return null;
      const entries = this.getTopEntries(answer.demography);

      const count = entries?.[0]?.answerersPercentage?.length;
      const colors = ['#f59425', '#3b8e80', '#e42e4d', '#2856ad', '#f56b4a', '#d800f8', '#00ffd0', '#a9fc00', '#003789'];
      const series = [...Array(count)].map((_, questionIndex) => {
        return {
          ...{
            type: this.chartType === 'polar' ? 'line' : 'column',
            name: this.reportData.questionnaires[questionIndex][`name${this.lang.value}`],
            marker: {
              enable: true,
              symbol: 'circle',
            },
            color: colors[questionIndex],
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.unitType === 'average' ? to2Dec(this.y) : to0Dec(this.y) + ' %';
              },
            },
            data: entries.map(e => {
              return {
                y: this.unitType === 'average' ? to2Dec(e.average[questionIndex]) : to0Dec(e.answerersPercentage[questionIndex]),
                name: e[`name${this.lang.value}`],
              };
            }),
          },
          ...(this.chartType === 'polar'
            ? {
                color: colors[questionIndex],
              }
            : {}),
        };
      });

      let options = {
        chart: {
          type: this.chartType === 'polar' ? 'line' : 'column',
          inverted: true,
          polar: this.chartType === 'polar',
          width: 1000,
          height: this.chartHeight(entries),
          plotBackgroundColor: null,
          marginTop: 100,
          marginRight: 20,
        },
        title: this.chartTitle,
        credits: {
          enabled: false,
        },
        subtitle: {
          text: this.selectedDemography[`name${this.lang.value}`],
        },
        legend: {
          align: 'top',
          verticalAlign: 'top',
          layout: 'horizontal',
          y: 50,
        },
        xAxis: {
          categories: entries.map(e => e[`name${this.lang.value}`]),
          labels: {
            style: {
              color: '#000000',
              fontSize: '16px',
              textOverflow: 'none',
            },
          },
        },

        yAxis: {
          labels: {
            style: {
              color: '#000000',
              fontSize: '16px',
            },
          },
        },
        series: series,
        tooltip: {
          /*pointFormatter: function(){
            console.log(this);
            return `<span style="color:${this.color}"></span> ${this.series.name}: <b>${parseInt(this.y)} %</b><br/>`
          }*/
          shared: true,
          pointFormat: `<span>{series.name}: <b>{point.y:,.2f} ${this.unitType === 'average' ? '' : '%'}</b><br/>`,
        },
        plotOptions: {
          column: {
            pointWidth: 18,
            borderRadiusTopLeft: '50%',
            borderRadiusTopRight: '50%',
            borderRadiusBottomLeft: '50%',
            borderRadiusBottomRight: '50%',
            maxPointWidth: 20,
          },
        },
        /*plotOptions: {
          series: {
            colorByPoint: true,
          },
        },*/
      };
      if (this.chartType === 'polar') {
        options = {
          ...options,
          ...{ chart: { ...options.chart, inverted: false } },
          ...{
            xAxis: {
              categories: entries.map(e => e[`name${this.lang.value}`]),
              tickmarkPlacement: 'on',
              labels: {
                style: {
                  color: '#000000',
                  fontSize: 1,
                },
              },
            },

            yAxis: {
              start: 0,
              angle: 0,
              alignTicks: false,
              tickInterval: 10,
              softMax: 101,
              softMin: 1,
              gridLineInterpolation: 'circle',
              lineWidth: 0,
              min: 0,
              opposite: true,
              panningEnabled: false,
              startOnTick: false,

              labels: {
                style: {
                  color: '#000000',
                  fontSize: '16px',
                },
              },
              // offset: -50
            },
            plotOptions: {
              column: {
                borderRadiusTopLeft: '50%',
                borderRadiusTopRight: '50%',
                borderRadiusBottomLeft: '50%',
                borderRadiusBottomRight: '50%',
              },
            },
          },
        };
      }
      return options;
    },
    getYMax(entries) {
      return entries.some(e => e.properties?.includes('SELECT_TOP_X'))
        ? Math.max(...entries.map(e => e.maxPoints[0]))
        : this.unitType === 'average'
        ? Math.max(...entries.map(e => e.singleMax))
        : 100;
    },
    chartHeight(entries, series) {
      const entryCount = this.topEntries > 0 ? Math.min(this.topEntries, entries.length) : entries.length;
      let times = 42 + (series?.length || 1) * 20;
      if (this.viewLevel === 'area') {
        if (entryCount <= 2) {
          return Math.max(300);
        }
        return Math.max(450, entryCount * times);
      }
      if (this.viewLevel === 'dimension') {
        if (entryCount <= 2) {
          return Math.max(300);
        }
        return Math.max(450, entryCount * times);
      }
      if (this.viewLevel === 'question') {
        if (entryCount <= 2) {
          return Math.max(300);
        }
        let _times = 42 + (series?.length || 1) * 32;
        return Math.max(450, entryCount * _times);
      }
      if (this.viewLevel === 'demographySplit' && entryCount < 5) {
        return Math.max(450, entryCount * 100);
      }
      if (series?.data?.length <= 4) {
        return Math.max(300, series.data.length * 100);
      }

      return 1000;
    },
    getTopEntries(entries) {
      return entries.slice(0, this.topEntries || 999999);
    },
    pickTextColorBasedOnBgColorAdvanced(bgColor, lightColor, darkColor) {
      let color = bgColor?.charAt(0) === '#' ? bgColor?.substring(1, 7) : bgColor || '';
      let r = parseInt(color.substring(0, 2), 16); // hexToR
      let g = parseInt(color.substring(2, 4), 16); // hexToG
      let b = parseInt(color.substring(4, 6), 16); // hexToB
      let uicolors = [r / 255, g / 255, b / 255];
      let c = uicolors.map(col => {
        if (col <= 0.03928) {
          return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
      });
      let L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
      return L > 0.179 ? darkColor : lightColor;
    },
    onAnswerersChange() {
      this.$nextTick(() => {
        this.getReportData();
      });
    },
  },
  computed: {
    questionnaireTypeForStarChart() {
      switch (this.questionnaire.type) {
        case 'Motivation':
          return 'ME';
        case 'Personality':
          return 'PE';
        case '360':
          return '360';
        case 'CDD':
          return 'CDD';
        default:
          return '';
      }
    },
    disabledTopEntries() {
      return !['gap', 'highest', 'lowest'].includes(this.chartType);
    },
    demographyQuestions() {
      return this.questionnaire?.questions?.filter(q => q.answerType?.toUpperCase() === 'DEMOGRAPHY') || [];
    },
    areaColors() {
      return this.$store.state.areaColors;
    },
    filteredQuestionnaires() {
      return this.questionnaires
        .filter(q => q.id !== this.questionnaire.id)
        .map(q => {
          q.name = q[`name${this.lang.value}`] + ' (' + this.$formatDateNoTime(q.createdAt) + ')';
          return q;
        });
    },
    filteredReportDataAnswers() {
      return this.reportData?.answers?.filter(a => a.type === this.viewLevel);
    },
    filteredCombinedDataAnswers() {
      /*if (this.unitType === 'average') {
        return [];
      }*/
      // eslint-disable-next-line
      return combined(structuredClone(this.reportData?.answers), this.viewLevel);
    },
    filteredReportDemographyAnswers() {
      if (!this.selectedDemography) return [];
      return this.reportData?.demographyAnswers?.filter(a => a.type === this.viewLevel && a.order === this.selectedDemography.order);
    },
    filteredReportDemographyAnswersCombined() {
      if (!this.selectedDemography) return [];
      // eslint-disable-next-line
      return combined(
        structuredClone(this.reportData?.demographyAnswers?.filter(a => a.order === this.selectedDemography.order)),
        this.viewLevel
      );
    },
    filteredReportDemographyAnswersCombinedStacked() {
      if (!this.selectedDemography) return [];
      const _answers = {};

      this.filteredReportDemographyAnswers.forEach(a => {
        const key = `${a.order}_${a.stageEn}`;
        _answers[key] = _answers[key] || [];
        _answers[key].push(a);
      });
      const fAnswers = [];
      Object.values(_answers).forEach(stageAnswers => {
        const entries = [];
        stageAnswers.forEach(a => {
          a.entries.forEach(e => {
            this.selectedDemography.demographies.forEach((d, di) => {
              const entry = {
                order: e.order,
                areaEn: e.areaEn,
                areaFi: e.areaFi,
                areaSv: e.areaSv,
                nameEn: d.nameEn,
                nameFi: d.nameFi,
                nameSv: d.nameSv,
                stageEn: a.stageEn,
                questionnaire: this.questionnaires.find(q => q.id === a.questionnaireId),
                demographyId: d.demographyId,
                percentage: [e.percentage[di]],
                points: [e.points[di]],
                maxPoints: [e.maxPoints[di]],
              };
              const foundEntry = entries.find(
                e => e.demographyId === entry.demographyId && e.areaEn === entry.areaEn && e.stageEn === entry.stageEn
              );
              if (foundEntry) {
                foundEntry.percentage.push(entry.percentage[0]);
                foundEntry.points.push(entry.points[0]);
                foundEntry.maxPoints.push(entry.maxPoints[0]);
              } else {
                entries.push(entry);
              }
            });
          });
        });
        entries.forEach(e => {
          const key = `${e.areaEn}_${e.stageEn}`;
          fAnswers[key] = fAnswers[key] || [];
          fAnswers[key].push(e);
        });
      });

      const finalAnswers = [];
      Object.values(fAnswers).forEach(e => {
        const a = {
          order: e[0].order,
          entries: e,
          questionnaire: null,
          stageEn: e[0].stageEn,
          stageFi: e[0].stageFi,
          stageSv: e[0].stageSv,
          nameEn: e[0].areaEn,
          nameFi: e[0].areaFi,
          nameSv: e[0].areaSv,
          type: this.viewLevel,
        };
        finalAnswers.push(a);
      });
      return finalAnswers;
    },
    chartTitle() {
      return {
        text: this.reportData.questionnaires
          .map(q => q[`name${this.lang.value}`])
          .filter(Boolean)
          .join(' vs '),
      };
    },
    languageVersionsEnabled() {
      return this.questionnaire.nameEn && this.questionnaire.nameSv && this.questionnaire.nameFi;
    },
    VIEWQUESTION() {
      return this.viewLevel === 'question';
    },
    langFile() {
      return langFile;
    },
    answerers() {
      return orderBy(
        this.questionnaire?.answererQuestionnaires?.map(aq => aq.answerer),
        ['firstname', 'lastname'],
        ['asc', 'asc']
      );
    },
  },
  mounted() {
    this.reloadAll();
  },
  created() {
    numeral.locale('fi');
  },
  watch: {
    disabledTopEntries() {
      if (this.disabledTopEntries) {
        this.topEntries = null;
      }
    },
    viewLevel(to) {
      if (to === 'question' && this.chartType === 'bar_stacked') {
        this.chartType = 'bar';
      }
      if (to !== 'area' && this.chartType === 'star') {
        this.chartType = 'bar';
      }
    },
    selectedDemography(val) {
      if (val) {
        this.chartType = 'bar';
        this.viewLevel = 'dimension';
        this.getReportData();
        //this.getAnswersByDemography(val);
      }
    },
    selectedQuestionnaire(newVal, oldVal) {
      if (newVal != oldVal) {
        this.reloadAll();
      }
    },
  },
};

function to2Dec(numb) {
  if (!isNaN(numb)) {
    if (numb < 0) numb = 0;
    return +(Math.round(numb + 'e+2') + 'e-2');
  }
  return numb;
}

function to0Dec(numb) {
  if (!isNaN(numb)) {
    if (numb < 0) numb = 0;
    return +(Math.round(numb + 'e+0') + 'e-0');
  }
  return numb;
}

(function (factory) {
  'use strict';

  if (typeof module === 'object' && module.exports) {
    module.exports = factory;
  } else {
    factory(Highcharts);
  }
})(function (H) {
  var rel = H.relativeLength;

  H.wrap(H.seriesTypes.column.prototype, 'translate', function (proceed) {
    var options = this.options,
      topMargin = options.topMargin || 0,
      bottomMargin = options.bottomMargin || 0;

    proceed.call(this);

    this.points.forEach(function (point) {
      var shapeArgs = point.shapeArgs,
        w = shapeArgs.width,
        h = shapeArgs.height,
        x = shapeArgs.x,
        y = shapeArgs.y;

      // Get the radius
      var rTopLeft = rel(options.borderRadiusTopLeft || 0, w),
        rTopRight = rel(options.borderRadiusTopRight || 0, w),
        rBottomRight = rel(options.borderRadiusBottomRight || 0, w),
        rBottomLeft = rel(options.borderRadiusBottomLeft || 0, w);

      if (rTopLeft || rTopRight || rBottomRight || rBottomLeft) {
        var maxR = Math.min(w, h) / 2;

        if (rTopLeft > maxR) {
          rTopLeft = maxR;
        }

        if (rTopRight > maxR) {
          rTopRight = maxR;
        }

        if (rBottomRight > maxR) {
          rBottomRight = maxR;
        }

        if (rBottomLeft > maxR) {
          rBottomLeft = maxR;
        }

        // Preserve the box for data labels
        point.dlBox = point.shapeArgs;

        point.shapeType = 'path';
        point.shapeArgs = {
          d: [
            ['M', x + rTopLeft, y + topMargin],
            // top side
            ['L', x + w - rTopRight, y + topMargin],
            // top right corner
            ['C', x + w - rTopRight / 2, y, x + w, y + rTopRight / 2, x + w, y + rTopRight],
            // right side
            ['L', x + w, y + h - rBottomRight],
            // bottom right corner
            ['C', x + w, y + h - rBottomRight / 2, x + w - rBottomRight / 2, y + h, x + w - rBottomRight, y + h + bottomMargin],
            // bottom side
            ['L', x + rBottomLeft, y + h + bottomMargin],
            // bottom left corner
            ['C', x + rBottomLeft / 2, y + h, x, y + h - rBottomLeft / 2, x, y + h - rBottomLeft],
            // left side
            ['L', x, y + rTopLeft],
            // top left corner
            ['C', x, y + rTopLeft / 2, x + rTopLeft / 2, y, x + rTopLeft, y],
            ['Z'],
          ],
        };
      }
    });
  });
});
</script>

<style lang="scss">
.bar_stacked {
  .highcharts-series-group {
    .highcharts-series:nth-child(1) {
      .highcharts-point {
        transform: translate(0, 28px);
      }
    }

    .highcharts-negative {
      .highcharts-point {
        transform: translate(0, -28px);
      }
    }
  }
}
</style>
<style scoped>
tr.trow:hover {
  background-color: #616161 !important;
}
</style>
