<template>
  <v-app id="app" light>
    <confirm-dialog ref="confirm" />
    <template v-if="showApplication">
      <template v-if="loadingOverlay">
        <v-fade-transition>
          <div class="loading-overlay">
            <v-row align="center" class="fill-height" justify="center">
              <v-col class="text-center" cols="4">
                <img src="/img/signium.png" style="width: 100px; height: auto; opacity: 0.4" />
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
      </template>

      <v-snackbar v-model="snackbar" :color="notification.color" :timeout="notification.timeOut" multi-line top>
        {{ notification.text }}
        <template v-slot:action>
          <template v-if="notification.showButton">
            <v-btn
              outlined
              small
              text
              @click="
                () => {
                  notification.callback();
                  snackbar = false;
                }
              "
            >
              {{ notification.buttonText }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn icon @click="snackbar = false">
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </template>
      </v-snackbar>

      <v-navigation-drawer
        v-if="shouldMenuBeAvailable"
        v-model="menuOpenState"
        :mini-variant="miniMenu && $vuetify.breakpoint.lgAndUp"
        app
        dark
        fixed
        width="300"
      >
        <v-list class="pa-0 elevation-4" light style="background: rgb(245, 246, 250)">
          <v-list-item style="padding: 2px 10px">
            <v-list-item-avatar style="margin-top: 10px; margin-bottom: 10px" @click.stop="toggleMiniMenu">
              <img src="/img/signium.png" style="height: 100%; width: auto" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="subheading font-weight-bold">{{ $t('title') }}</v-list-item-title>
              <v-list-item-subtitle v-if="$isLoggedIn" class="caption">{{ $userInfo.email }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon small @click.stop="toggleMiniMenu">
                <v-icon>chevron_left</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-list :dense="$vuetify.breakpoint.mdAndDown">
          <template v-if="$userInfo && $userInfo.tenants.length > 1">
            <TenantSelect :miniMenu="miniMenu" />
            <v-list-item>
              <v-select
                v-model="selectedQuestionnaireType"
                :items="types"
                class="mt-1 mb-3"
                clearable
                hide-details
                label="Evaluation type"
                outlined
              ></v-select>
            </v-list-item>
          </template>

          <template v-if="filteredPrimaryPages.length > 0">
            <v-list-item
              v-for="(page, index) in filteredPrimaryPages"
              :key="index + 'primary'"
              :to="getPageLink(page)"
              exact
              @click="closeMenuIfOnSamePage(page.link)"
            >
              <v-list-item-action>
                <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>

          <template v-if="filteredSecondaryPages.length > 0">
            <v-list-item
              v-for="(page, index) in filteredSecondaryPages"
              :key="index + 'secondary'"
              :to="getPageLink(page)"
              exact
              @click="closeMenuIfOnSamePage(page.link)"
            >
              <v-list-item-action>
                <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>

          <template v-if="filteredTenantAdminPages.length > 0">
            <v-divider></v-divider>
            <v-subheader v-if="!miniMenu">{{ $t('menu_subheaders.organization_admin') }}</v-subheader>
            <v-list-item
              v-for="(page, index) in filteredTenantAdminPages"
              :key="index + 'tadmin'"
              :to="getPageLink(page)"
              exact
              @click="closeMenuIfOnSamePage(page.link)"
            >
              <v-list-item-action>
                <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>

          <template v-if="filteredAdminPages.length > 0">
            <v-subheader v-if="!miniMenu">{{ $t('menu_subheaders.application_admin') }}</v-subheader>
            <v-list-item
              v-for="(page, index) in filteredAdminPages"
              :key="index + 'admin'"
              :to="getPageLink(page)"
              exact
              @click="closeMenuIfOnSamePage(page.link)"
            >
              <v-list-item-action>
                <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>

          <v-list-item :to="{ name: 'userprofile' }" @click="closeMenuIfOnSamePage('userprofile')">
            <v-list-item-action>
              <v-icon color="grey lighten-1">mdi-account-settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('user_profile.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon color="grey lighten-1">logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('login.logout') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <router-view />
      </v-main>

      <v-bottom-navigation v-if="$vuetify.breakpoint.mdAndDown && this.$isLoggedIn" :value="bottomNavSelected" app color="white" grow>
        <template v-for="(page, index) in filteredPrimaryPages">
          <v-btn :key="index" :value="page.link" color="primary" text @click="goToLink(page.link)">
            <span>{{ page.title }}</span>
            <v-icon>{{ page.icon }}</v-icon>
          </v-btn>
        </template>
      </v-bottom-navigation>
    </template>
    <template v-else>
      <NoTenantsView></NoTenantsView>
    </template>
  </v-app>
</template>

<script>
import TenantSelect from './components/TenantSelect.vue';
import TenantService from './service/tenant.service';
import NoTenantsView from './views/NoTenantsView.vue';
import ConfirmDialog from '@/components/ConfirmDialog';
import UiEventBus from '@/UiEventBus';

import * as Sentry from '@sentry/vue';

export default {
  components: { TenantSelect, NoTenantsView, ConfirmDialog },
  data() {
    return {
      notification: {
        color: '',
        text: '',
        buttonText: '',
        showButton: false,
        timeOut: -1,
        callback: () => {},
      },
      snackbar: false,
      miniMenu: false,
      bottomNavSelected: '',
      showBottomNav: true,
      menuOpenState: false,
      pages: [
        {
          title: this.$t('questionnaire._s'),
          icon: 'access_time',
          link: 'tenant_questionnaires',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_basic', 'tenant_admin'],
          primary: true,
        },
      ],
      tenantPages: [
        {
          title: this.$t('tenants.management_title'),
          icon: 'settings',
          link: 'tenant_settings',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin'],
          primary: false,
        },
        {
          title: this.$t('tenants.organization_users'),
          icon: 'people',
          link: 'tenant_users',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin'],
          primary: false,
        },
      ],
      adminPages: [
        /*{
          title: this.$t('start.title'),
          icon: 'accessibility',
          link: 'tenant_start',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: [],
          primary: true,
        },*/
        /*{
            title: 'Tenant Test',
            icon: 'people',
            link: 'tenant_test',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: true,
          },*/
        {
          title: this.$t('user_management.title'),
          icon: 'people',
          link: 'users',
          roles: ['ADMIN'],
          primary: false,
        },
        {
          title: this.$t('tenants.title'),
          icon: 'business',
          link: 'tenants',
          roles: ['ADMIN'],
          primary: false,
        },
      ],
    };
  },
  methods: {
    getPageLink(page) {
      const link = {
        name: page.link,
      };

      if ('params' in page) {
        link.params = page.params();
      }
      return link;
    },
    logout() {
      UiEventBus.$emit('userLoggedOut');
    },
    goToLink(name) {
      this.$router.push({ name: name });
    },
    toggleMiniMenu() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.miniMenu = !this.miniMenu;
      } else {
        this.$store.commit('toggleMainMenuState');
      }
    },
    closeMenuIfOnSamePage(link) {
      if (this.$vuetify.breakpoint.mdAndDown && this.$route.name === link) {
        this.menuOpenState = false;
      }
    },
    handleSelectedBottomNavItem(name) {
      const isPagePrimary = this.pages.findIndex(p => p.primary && p.link === name) > -1;
      if (isPagePrimary) {
        this.bottomNavSelected = name;
        this.showBottomNav = true;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  watch: {
    $isLoggedIn(newValue, oldValue) {
      console.log('isLoggedInChange', newValue, oldValue);
      if (newValue === true) {
        UiEventBus.$emit('loginSuccess');
      }
    },
    loadingOverlay(to) {
      if (to) {
        setTimeout(() => {
          this.$store.commit('setLoadingOverlay', false);
        }, 2000);
      }
    },
    notificationObject(to) {
      this.notification.color = to.color;
      this.notification.text = to.message;
      this.snackbar = true;
      this.notification.buttonText = to.buttonText;
      this.notification.showButton = to.showButton;
      this.notification.timeOut = to.timeOut;
      this.notification.callback = to.callback;
    },
    menuOpenComputed(to) {
      this.menuOpenState = to;
    },
    menuOpenState(to) {
      if (to !== this.$store.state.mainMenuOpen) {
        this.$store.commit('toggleMainMenuState');
      }
    },
    $route(to, from) {
      if (from && from.name) this.$store.commit('setPreviousRouteName', from.name);
      this.handleSelectedBottomNavItem(to.name);
    },
    currentRouteName(val) {
      if (!this.$isLoggedIn && val !== 'login') {
        this.$router.push({ name: 'login' });
      }
    },
  },
  computed: {
    types() {
      return this.$store.state.questionnaireTypes;
    },
    showApplication() {
      return !this.$isLoggedIn || this.$isAdmin || (this.$userInfo && this.$userInfo.tenants.length > 0);
    },
    loadingOverlay() {
      return this.$store.state.loadingOverlay;
    },
    shouldMenuBeAvailable() {
      return this.$isLoggedIn;
    },
    notificationObject() {
      return this.$store.state.notificationObject;
    },
    menuOpenComputed() {
      let menuState = true;
      if (this.$vuetify.breakpoint.mdAndDown) {
        menuState = this.$store.state.mainMenuOpen;
      }
      return menuState;
    },
    filteredSecondaryPages() {
      if (!this.$tenant) {
        return [];
      }
      let pages = [];
      this.pages.forEach(page => {
        if (!page.primary) {
          pages.push(page);
        }
      });
      return pages;
    },
    filteredTenantAdminPages() {
      if (this.$tenant && this.$userInfo.roles.indexOf('tenant_admin') !== -1) {
        return this.tenantPages;
      }
      return [];
    },
    filteredAdminPages() {
      if (this.$isAdmin) {
        return this.adminPages;
      }
      return [];
    },
    filteredPrimaryPages() {
      if (!this.$tenant) {
        return [];
      }
      let pages = [];
      this.pages.forEach(page => {
        if (page.primary) {
          pages.push(page);
        }
      });
      return pages;
    },
    currentRouteName() {
      return this.$route.name;
    },
    selectedQuestionnaireType: {
      get() {
        return this.$store.state.selectedQuestionnaireType;
      },
      set(value) {
        this.$store.commit('setSelectedQuestionnaireType', value);
      },
    },
  },
  created() {
    // If application is opened from link, and some other tenant id has been saved in tenant service
    // This sets the one from URL
    if ('tenantId' in this.$router.currentRoute.params) {
      TenantService.setTenant(this.$router.currentRoute.params.tenantId);
    }
  },
  mounted() {
    if (this.$userInfo) {
      if (Sentry) {
        Sentry.setUser({ email: this.$userInfo.email });
      }
    }
    if (this.$vuetify.breakpoint.lgAndUp) this.menuOpenState = true;
    this.$root.$confirm = this.$refs.confirm.open;
    this.handleSelectedBottomNavItem(this.$route.name);
  },
};
</script>

<style lang="scss" scoped>
@import './sass/commonstyles.scss';
</style>
