<template>
  <v-layout>
    <v-row v-if="!codeSent">
      pyydä koodi
      <v-text-field label="email or phone" v-model="subject"></v-text-field>
      <v-btn @click="requestCode">send code</v-btn>
    </v-row>
    <v-row v-else>
      <v-col xs="12" cols="12">
        kirjaudu (ei cognito)
      </v-col>
      <v-col xs="12" cols="12">
        <v-text-field label="user" v-model="username"></v-text-field>
      </v-col>
      <v-col xs="12" cols="12">
        <v-text-field label="password" v-model="password"></v-text-field>
      </v-col>
      <v-btn @click="loginWithCode">kirjaudu puh/email + koodi</v-btn>
    </v-row>
  </v-layout>
</template>

<script>
import publicApi from "@/api/public";
import libphonenumber from "google-libphonenumber";
import axios from "axios";
import UserService from "@/service/user.service";
import userApi from "@/api/user";
import UiEventBus from "@/UiEventBus";

export default {
  name: "LoginWithCode",
  data() {
    return {
      codeSent: false,
      subject: '',
      username: '',
      password: '',
    }
  },
  methods: {
    async loginWithCode() {
      try {
        const formData = new FormData();

        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

        let loginType = null;
        try {
          phoneUtil.parseAndKeepRawInput(this.username, 'FI');
          loginType = 'SMS';
        } catch (ignored) { // is not valid
        }

        if (loginType == null && this.$isValidEmail(this.username)) {
          loginType = 'EMAIL';
        }

        if (loginType == null) {
          console.log('not sms or email', this.username);
          return;
        }

        formData.append("subject", this.username);
        formData.append("code", this.password);
        formData.append("loginType", loginType);
        await axios.post('/login_code?remember_me=1', formData);
        UserService.springLogin();
        const me = await userApi.getMe();
        UserService.setUserInfo(me);
        UiEventBus.$emit('loginSuccess');
      } catch (error) {
        console.log(error);
      }
    },
    async requestCode() {
      try {
        await publicApi.sendLoginCode(this.subject);
        this.codeSent = true;
      } catch (error) {
        this.$handleApiError(error);
      }
    },
  },
}
</script>

<style scoped>

</style>
