import Vue from 'vue';
import TenantService from './tenant.service';

const AUTH_TYPES = {
  COGNITO: 'cognito',
  SPRING: 'spring',
};

export { AUTH_TYPES };

export default new Vue({
  data() {
    return {
      innerUserInfo: JSON.parse(localStorage.getItem("userInfo" )),
      innerSession: JSON.parse(localStorage.getItem("session" )),
      authType: localStorage.getItem("auth_type"),
    }
  },
  computed: {
    loggedIn() {
      return this.innerUserInfo != null;
    }
  },
  methods: {
    cognitoLogin(cognitoSession) {
      this.setAuthType(AUTH_TYPES.COGNITO)
      this.setSession(cognitoSession);
    },
    springLogin() {
      this.setAuthType(AUTH_TYPES.SPRING)
      this.setSession(null);
    },
    setAuthType(authType) {
      localStorage.setItem('auth_type', authType);
      this.authType = authType;
    },
    setSession(session) {
      localStorage.setItem("session", JSON.stringify(session));
      this.innerSession = session;
    },
    getSession() {
      return this.innerSession;
    },
    setUserInfo(getMeResponse) {
      const currentTenant = TenantService.tenant;
      if (currentTenant == null || getMeResponse.tenants.findIndex(t => t.id === currentTenant) === -1) {
        if (getMeResponse.tenants.length > 0) {
          TenantService.setTenant(getMeResponse.tenants[0].id);
        } else {
          TenantService.clear();
        }
      }

      const userInfo = {};
      userInfo.roles = getMeResponse.roles;
      userInfo.firstName = getMeResponse.firstName;
      userInfo.lastName = getMeResponse.lastName;
      userInfo.username = getMeResponse.username;
      userInfo.phone = getMeResponse.phone;
      userInfo.agentUser = getMeResponse.agentUser;
      userInfo.enabled = getMeResponse.enabled;
      userInfo.subject = getMeResponse.subject;
      userInfo.id = getMeResponse.id;
      userInfo.imageKey = getMeResponse.imageKey;
      userInfo.email = getMeResponse.email;
      userInfo.tenants = getMeResponse.tenants;

      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      this.innerUserInfo = userInfo;


    },
    getUserInfo() {
      return this.innerUserInfo;
    },
    clearUserInfo() {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("session");
      localStorage.removeItem("auth_type");
      this.innerUserInfo = null;
      this.innerSession = null;
      this.authType = null;
    }
  },
})
