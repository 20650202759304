<template>
  <hb-basic-page :search-title="`${$t('search')}...`"
                 :loading="loading"
                 @handle-search="handleSearch"
                 name="start-view">

    <v-col cols="12">
      <dummy-block />
    </v-col>

  </hb-basic-page>
</template>

<script>
  import DummyBlock from '../components/Start/DummyBlock.vue';

  export default {
    name: "StartView",
    components: {
      DummyBlock: DummyBlock,
    },
    data() {
      return {
        loading: true,
        searchPhrase: '',
      }
    },
    methods: {
      handleSearch(searchPhrase) {
        this.searchPhrase = searchPhrase;
      },
    },
    mounted() {
      this.loading = false;
    },
  }
</script>

<style scoped>

</style>
