import axios from 'axios';

/*
 * Public api calls
 * /api/public/**
 *
 * No authentication required for calls
 */
const publicApi = {
  async sendLoginCode(subject) {
    const loginType = subject.indexOf('@') !== -1 ? 'EMAIL' : 'SMS'

    const data = {
      subject,
      loginType,
    }

    const response = await axios.post('/api/public/login/code', data);
    return response.data;
  }
}

export default publicApi;
