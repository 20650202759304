<template>
  <hb-basic-page :search-title="`${$t('user_management.search')}...`"
              :subtle-loading="subtleLoading"
              :loading="loading"
              name="app-users-list"
              @handle-search="handleSearch"
              :noContainerPadding="$vuetify.breakpoint.xsOnly"
              no-gutters>

    <v-col cols="12" sm="8" lg="9" xl="10" :style="{height: $vuetify.breakpoint.xsOnly ? '0' : 'auto'}">
      <add-user-dialog @on-dismiss="onAddUserDialogDismiss" :roles="roles" />
    </v-col>
    <v-col cols="12" sm="4" lg="3" xl="2">
      <v-select
        v-model="filter"
        :items="filterOptions"
        :placeholder="`${$t('user_management.filtered')} 0 ${$t('user_management.selections')}`"
        item-text="desc"
        item-value="value"
        multiple
        hide-details
        single-line
        solo
        prepend-inner-icon="filter_list"
        :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : ''"
      >
        <template v-slot:selection="{ item, index }">
          <template v-if="filter.length !== filterOptions.length && index === 0">
            <span class="grey--text mr-1">{{ $t('user_management.filtered') }}</span>
            <span class="grey--text caption">({{ filter.length }} {{ $t('user_management.selections') }})</span>
          </template>
          <template v-else>
            <span v-if="index === 0" class="grey--text">{{ $t('user_management.show_all') }}</span>
          </template>
        </template>
      </v-select>
    </v-col>
    <v-col cols="12" v-if="(filteredLocalUsers.length > 0 && !cloudUsersAreFilteredOut) || (filteredCloudUsers.length > 0 && !localUsersAreFilteredOut)" :pb-5="$vuetify.breakpoint.xsOnly">
      <v-list two-line color="transparent" class="pa-0 pb-15">
        <v-list-item class="users-header" v-if="$vuetify.breakpoint.smAndUp">
          <v-list-item-avatar></v-list-item-avatar>
          <v-list-item-content class="grey--text">
            <v-container fluid ma-0 pa-0>
              <v-row align="center" justify="center" class="fill-height" ma-0>
                <v-col cols="12" sm="6" lg="5" xl="3">
                  <span class="caption">{{ $t('user_management.user') }}</span>
                </v-col>
                <v-col cols="12" sm="6" lg="7" xl="9">
                  <span class="caption">{{ $t('user_management.roles') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
          <v-list-item-action style="width: 36px;"></v-list-item-action>
        </v-list-item>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
        <users-list v-if="filter.findIndex(f => f === 1) > -1" :users="filteredLocalUsers" :allRoles="roles" type="local" @reload-users="getAllUsers" />
        <users-list v-if="filter.findIndex(f => f === 0) > -1" :users="filteredCloudUsers" :allRoles="roles" type="user" @reload-users="getAllUsers" />
      </v-list>
    </v-col>

  </hb-basic-page>
</template>

<script>
  import UsersList from '../components/UserManagement/UsersList.vue';
  import AddUserDialog from '../components/UserManagement/AddUserDialog.vue';
  import adminApi from "../api/admin";
  import systemApi from "../api/system";

  export default {
    name: "UserManagementView",
    components: {
      UsersList: UsersList,
      AddUserDialog: AddUserDialog,
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        searchPhrase: '',
        cloudUsers: [],
        localUsers: [],
        roles: [],
        filter: [0,1,2,3],
        filterOptions: [
          { desc: "Tavalliset käyttäjät", value: 0 },
          { desc: "Jaetut käyttäjät", value: 1 },
          { desc: "Aktiiviset", value: 2 },
          { desc: "Ei aktiiviset", value: 3 },
        ],
      }
    },
    methods: {
      handleSearch(searchPhrase) {
        this.searchPhrase = searchPhrase;
      },
      onAddUserDialogDismiss() {
        this.getAllUsers();
      },
      async reloadLocalUsers() {
        try {
          this.localUsers = await adminApi.getLocalUsers()
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      async reloadCloudUsers() {
        try {
          this.cloudUsers = await adminApi.getCloudUsers()
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      async getAllUsers() {
        if (this.localUsers.length > 0 || this.cloudUsers.length > 0) {
          this.subtleLoading = true;
        } else {
          this.loading = true;
        }
        await this.reloadCloudUsers();
        await this.reloadLocalUsers();
        this.loading = false;
        this.subtleLoading = false;
      },
      filterUsers(users) {
        const filteredUsers = users;

        const filterOutActive = this.filter.findIndex(f => f === 2) === -1;
        const filterOutInactive = this.filter.findIndex(f => f === 3) === -1;

        if ((this.searchPhrase == null || this.searchPhrase.length === 0) && !filterOutActive && !filterOutInactive) {
          return filteredUsers;
        }

        const searchPhraseLower = this.searchPhrase.toLowerCase();
        const searchPhraseArray = searchPhraseLower.split(" ");
        return filteredUsers.filter(user => {
          let found = [];
          const lowerSearchedString =
            (user.email ? user.email.toLowerCase() : '') +
            (user.subject ? user.subject.toLowerCase() : '') +
            (user.roleSet && user.roleSet.length > 0 ? user.roleSet.join(' ').toLowerCase() : '');
          searchPhraseArray.forEach(phrase => {
            if (lowerSearchedString.indexOf(phrase) !== -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          })
          if (filterOutActive && user.enabled) found.push(false);
          if (filterOutInactive && !user.enabled) found.push(false);
          return found.indexOf(false) === -1;
        })
      },
      async getRoles() {
        try {
          this.roles = await systemApi.getRoles();
        } catch (error) {
          this.$handleApiError(error);
        }
      },
    },
    computed: {
      filteredLocalUsers() {
        return this.filterUsers(this.localUsers);
      },
      filteredCloudUsers() {
        return this.filterUsers(this.cloudUsers);
      },
      localUsersAreFilteredOut() {
        return this.filter.findIndex(f => f === 1) === -1;
      },
      cloudUsersAreFilteredOut() {
        return this.filter.findIndex(f => f === 0) === -1;
      },
    },
    mounted() {
      this.getAllUsers();
      this.getRoles();
    },
  }
</script>

<style lang="scss">
  .users-header {
    .v-list__tile {
      height: 30px;
    }
  }
</style>
