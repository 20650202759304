<template>
  <v-dialog v-model="sendEmailDialog" width="600">
    <v-card>
      <v-card-title>Send reminder emails</v-card-title>
      <v-card-text v-if="loadingRecipients">
        <v-progress-circular color="primary" indeterminate />
      </v-card-text>
      <v-card-text v-if="!loadingRecipients">
        <v-text-field :label="`Email recipients (${emailRecipients.count})`" :value="emailRecipients.string" readonly />
        <v-text-field v-model="questionnaire.reminderEmailSubject" label="Reminder email subject" readonly />
        <v-textarea v-model="questionnaire.reminderEmailTemplate" label="Reminder email template" readonly />
        <v-alert color="warning"><code v-html="'{{URL}}'"></code> will be changed to answerer's corresponding url. </v-alert>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn color="error" outlined rounded small @click="sendEmailDialog = false">
          <v-icon>mdi mdi-close</v-icon>
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn :loading="loadingSendEmails" color="primary" outlined rounded small @click="sendEmails">
          <v-icon>mdi mdi-email-send</v-icon>
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import questionnaireApi from '@/api/questionnaire';
import emailApi from '@/api/email';

export default {
  name: 'send-reminder-emails',
  data() {
    return {
      showEmailWithoutUrlWarningDialog: false,
      sendEmailsWithoutUrl: false,
      loadingSendEmails: false,
      sendEmailDialog: false,
      //sentEmails: [],
      questionnaire: {},
      toSendEmailAddresses: [],
      loadingRecipients: false,
    };
  },
  props: {
    questionnaireId: {
      type: Number,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    linkBaseUrl() {
      return `${document.location.origin}/public/#/tenant/${this.$tenant}/questionnaire`;
    },
    emailRecipients() {
      const emails = this.toSendEmailAddresses.map(a => a.email);

      return {
        string: emails?.filter(Boolean)?.join(';') || '',
        count: emails?.length || 0,
        emails: emails?.filter(Boolean) || [],
      };
    },
  },
  watch: {
    async sendEmailDialog(val) {
      if (val) {
        //this.sentEmails = await emailApi.getSentEmails(this.questionnaireId);
        this.loadingRecipients = true;
        try {
          this.questionnaire = await questionnaireApi.getQuestionnaire(this.questionnaireId);

          this.toSendEmailAddresses = await questionnaireApi.getIncompleteAnswerers(this.questionnaireId);
        } catch (e) {
          console.error(e);
        }
        this.loadingRecipients = false;
      }
      this.$emit('input', val);
    },
    value(val) {
      this.sendEmailDialog = val;
    },
  },
  emits: ['sent', 'input'],
  methods: {
    async sendEmails() {
      if (this.sendEmailsWithoutUrl === false) {
        if (this.questionnaire.emailTemplate == null || !this.questionnaire.emailTemplate?.includes('{{URL}}')) {
          //this.$showErrorNotification('Email template must include {{URL}}');
          this.showEmailWithoutUrlWarningDialog = true;
          return;
        }
      }
      this.loadingSendEmails = true;
      this.sendEmailsWithoutUrl = false;
      try {
        await emailApi.sendReminderEmails(this.questionnaireId, this.emailRecipients.emails || [], this.linkBaseUrl);
        this.sendEmailDialog = false;

        this.$emit('sent');
        this.$showSuccessNotification('Emails sent!');
      } catch (e) {
        console.error(e);
        this.$showErrorNotification('Error on sending email ' + e);
      }
      this.loadingSendEmails = false;
    },
    acceptEmailWithoutUrlAndSend() {
      this.showEmailWithoutUrlWarningDialog = false;
      this.sendEmailsWithoutUrl = true;
      this.sendEmails();
    },
    cancelEmailTemplateWithoutUrl() {
      this.showEmailWithoutUrlWarningDialog = false;
    },
  },
};
</script>
