import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify); /*
$c-primary: rgb(16, 46, 102);
$c-primary-lighten1: rgb(135, 149, 178);
$c-primary-lighten2: rgb(207, 212, 223);

$c-green: rgb(162, 191, 174);
$c-green-lighten1: rgb(218, 229, 223);

$c-red: rgb(207, 168, 167);
$c-red-lighten1: rgb(236, 220, 219);

$c-yellow: rgb(249, 235, 181);
$c-yellow-lighten1: rgb(253, 248, 226);

$c-orange: rgb(224, 191, 171);
$c-orange-lighten1: rgb(243, 230, 220);*/
export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: false,
    themes: {
      /*light: {
        primary: '#102E66',
        secondary: '#A2BFAE',
        accent: '#F9EBB5',
        error: '#E0BFAB',
        warning: '#F9EBB5',
        info: '#8795B2FF',
        success: '#A2BFAE',
      },*/
      light: {
        primary: '#102E66',
        secondary: '#537f6d',
        accent: '#f8d593',
        error: '#fa7934',
        warning: '#fade45',
        info: '#6289af',
        success: '#42876a',
      },
      dark: {
        primary: '#006a64',
        secondary: '#19504d',
        accent: '#ffe600',
        error: '#F4511E',
        warning: '#ff9800',
        info: '#40c4ff',
        success: '#66bb6a',
      },
    },
  },
});
