import Vue from 'vue';
import Router from 'vue-router';
import LoginView from './views/LoginView.vue';
import RedirectToTenantStartView from "@/views/RedirectToTenantStartView";
import StartView from "@/views/StartView";

// Admin views
import UserManagementView from './views/UserManagementView.vue';
import Tenants from './views/TenantsView.vue';

// User views
import UserProfile from './views/UserProfile.vue';

// Tenant views
import TenantContainer from "./views/TenantContainer.vue";
import TenantUserManagementView from "./views/tenant/TenantUserManagementView.vue";
import SingleTenantTestView from "./views/tenant/SingleTenantTestView.vue";
import TenantManagementView from "./views/tenant/TenantManagementView.vue";


// App specific views

import QuestionnaireView from "@/views/tenant/QuestionnaireView";
import QuestionnaireAnswersView from "@/views/tenant/QuestionnaireAnswersView";
import QuestionnaireListView from "@/views/tenant/QuestionnaireListView";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/',
      name: 'start',
      component: RedirectToTenantStartView,
    },
    {
      path: '/users',
      name: 'users',
      component: UserManagementView,
    },
    {
      path: '/me',
      name: 'userprofile',
      component: UserProfile,
    },
    {
      path: '/dummy',
      name: 'dummy',
      component: StartView,
    },
    {
      path: '/t/:tenantId',
      props: true,
      component: TenantContainer,
      children: [
        {
          path: 'start',
          name: 'tenant_start',
          component: StartView,
        },
        {
          path: 'tenant_test',
          name: 'tenant_test',
          props: true,
          component: SingleTenantTestView,
        },
        {
          path: 'users',
          name: 'tenant_users',
          props: true,
          component: TenantUserManagementView,
        },
        {
          path: 'settings',
          name: 'tenant_settings',
          props: true,
          component: TenantManagementView,
        },
        {
          path: 'questionnaire',
          name: 'tenant_questionnaires',
          props: true,
          component: QuestionnaireListView,
        },
        {
          path: 'questionnaire/:id',
          name: 'tenant_questionnaire',
          props: true,
          component: QuestionnaireView,
        },
        {
          path: 'questionnaire/:id/answers',
          name: 'tenant_questionnaire_answers',
          props: true,
          component: QuestionnaireAnswersView,
        },
      ]
    },
    {
      path: '/tenants',
      name: 'tenants',
      component: Tenants,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})
