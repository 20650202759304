<template>
  <hb-basic-page :loading="true">

  </hb-basic-page>
</template>

<script>
export default {
  name: 'RedirectToTenantStartView',
  mounted() {
    if (this.$isLoggedIn && this.$tenant != null) {
      this.$router.push({ name: 'tenant_questionnaires', params: { tenantId: this.$tenant }})
    } else if (this.$isLoggedIn && this.$tenant == null && this.$isAdmin) {
      this.$router.push({ name: 'tenants' });
    }
  }
}
</script>

<style scoped>

</style>
