<template>
  <hb-basic-page :search-title="`${$t('user_management.search')}...`"
              :subtle-loading="subtleLoading"
              :loading="loading"
              name="tenant-users-list"
              @handle-search="handleSearch"
              :noContainerPadding="$vuetify.breakpoint.xsOnly"
              no-gutters>

        <v-col cols="12" :style="{height: $vuetify.breakpoint.xsOnly ? '0' : 'auto'}">
          <add-user-dialog @on-dismiss="onAddUserDialogDismiss" />
        </v-col>
        <v-col cols="12" v-if="filteredUsers.length > 0" :pb-5="$vuetify.breakpoint.xsOnly">
          <v-list two-line color="transparent" class="pa-0 pb-15">
            <v-list-item class="users-header" v-if="$vuetify.breakpoint.smAndUp">
              <v-list-item-avatar></v-list-item-avatar>
              <v-list-item-content class="grey--text">
                <v-container fluid ma-0 pa-0>
                  <v-row align="center" justify="center" class="fill-height" ma-0>
                    <v-col cols="12" sm="6" lg="5" xl="3">
                      <span class="caption">{{ $t('user_management.user') }}</span>
                    </v-col>
                    <v-col cols="12" sm="6" lg="7" xl="9">
                      <span class="caption">{{ $t('user_management.roles') }}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-list-item-content>
              <v-list-item-action style="width: 36px;"></v-list-item-action>
            </v-list-item>
            <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
            <users-list :users="filteredUsers" :allRoles="roles" type="user" @reload-users="getAllUsers" />
          </v-list>
        </v-col>

  </hb-basic-page>
</template>

<script>
  import UsersList from '../../components/Tenant/TenantUserManagement/UsersList.vue';
  import AddUserDialog from '../../components/Tenant/TenantUserManagement/AddUserDialog.vue';
  import tenantApi from "../../api/tenant";

  export default {
    name: "TenantUserManagementView",
    components: {
      UsersList: UsersList,
      AddUserDialog: AddUserDialog,
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        searchPhrase: '',
        users: [],
        roles: [],
      }
    },
    methods: {
      handleSearch(searchPhrase) {
        this.searchPhrase = searchPhrase;
      },
      onAddUserDialogDismiss() {
        this.getAllUsers();
      },
      async getAllUsers() {
        if (this.users.length > 0) {
          this.subtleLoading = true;
        } else {
          this.loading = true;
        }
        this.users = await tenantApi.loadTenantUsers();
        this.loading = false;
        this.subtleLoading = false;
      },
      filterUsers(users) {
        const filteredUsers = users.sort((a,b) => {
          return a.email < b.email ? -1 : b.email < a.email ? 1 : 0;
        });

        if (this.searchPhrase == null || this.searchPhrase.length === 0) {
          return filteredUsers;
        }

        const searchPhraseLower = this.searchPhrase.toLowerCase();
        const searchPhraseArray = searchPhraseLower.split(" ");
        return filteredUsers.filter(user => {
          let found = [];
          const lowerSearchedString =
            (user.email ? user.email.toLowerCase() : '') +
            (user.subject ? user.subject.toLowerCase() : '');
          searchPhraseArray.forEach(phrase => {
            if (lowerSearchedString.indexOf(phrase) !== -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          })
          return found.indexOf(false) === -1;
        })
      },
      async getRoles() {
        try {
          this.roles = await tenantApi.getRoles();
        } catch (error) {
          this.$handleApiError(error);
        }
      },
    },
    computed: {
      filteredUsers() {
        return this.filterUsers(this.users);
      },
    },
    mounted() {
      this.getAllUsers();
      this.getRoles();
    },
  }
</script>

<style lang="scss">
  .users-header {
    .v-list__tile {
      height: 30px;
    }
  }
</style>
