<template>
  <tr>
    <td>{{ item.code }}</td>
    <td>
      <div>
        <small>{{ item[`pretext${langSuffix}`] }}</small>
      </div>
      <strong>{{ item[`name${langSuffix}`] }}</strong>

      <!--<span v-if="item.descriptionEn && item.descriptionEn.length > 0 && item.descriptionEn.indexOf('\n') > -1"
      ><br /><v-textarea style="font-size: 13px" :value="item.descriptionEn" readonly
    /></span>
    <span v-else-if="item.descriptionEn && item.descriptionEn.length > 0"><br />{{ item.descriptionEn }}</span>-->
      <span
        ><vue-markdown :key="langSuffix">{{ item[`description${langSuffix}`] }}</vue-markdown></span
      >
    </td>

    <td>{{ item.area && item.area[`name${langSuffix}`] }}</td>
    <!--<td>{{ item.dimension && item.dimension.dimension && item.dimension.dimension.nameEn }}</td>-->
    <td>{{ item.dimension && item.dimension[`name${langSuffix}`] }}</td>
    <td>
      <span
        >{{ answerType }} <small v-if="answerTypeParts">({{ answerTypeParts }})</small></span
      >
      <small>{{
        (item.demographies &&
          item.demographies.length > 0 &&
          `(${item.demographies
            .map(r => r[`name${langSuffix}`])
            .filter(Boolean)
            .join(', ')})`) ||
        ''
      }}</small>

      <div v-if="extraInfo" class="caption">
        <br />
        {{ extraInfo }}
      </div>
    </td>
  </tr>
</template>

<script>
import VueMarkdown from 'vue-markdown';

export default {
  name: 'AdminQuestionRow',
  components: { VueMarkdown },
  props: {
    item: Object,
    lang: {
      default: 'en',
      type: String,
    },
  },
  computed: {
    extraInfo() {
      try {
        return this.item.extra?.join(', ');
      } catch (e) {
        return this.item.extra;
      }
    },
    answerType() {
      return this.item.answerType?.split(',')[0];
    },
    answerTypeParts() {
      let parts = [];
      let answerScale;

      if (this.item.answerType.includes('DESCRIBING') || this.item.answerType.includes('OPINION_SCALE')) {
        answerScale = this.item.answerType?.split(',').slice(1, 2);

        parts = this.item.answerType?.split(',').slice(2, this.item.answerType.length).join(',').split('|');

        let translation = this.switchLanguage(this.lang, parts);
        if (translation === '') {
          return '';
        } else {
          return `${answerScale},${translation}`;
        }
      } else {
        return '';
      }
    },
    langSuffix() {
      return this.lang;
    },
  },
  methods: {
    switchLanguage(language, parts) {
      let langIndex = 0;
      let langQs;

      switch (language) {
        case 'En':
          langIndex = 0;
          langQs = parts?.[langIndex];
          return langQs;

        case 'Sv':
          langIndex = 1;
          langQs = parts?.[langIndex];
          return langQs;

        case 'Fi':
          langIndex = 2;
          langQs = parts?.[langIndex];
          return langQs;
        case 'Da':
          langIndex = 3;
          langQs = parts?.[langIndex];
          return langQs;

        case 'No':
          langIndex = 4;
          langQs = parts?.[langIndex];
          return langQs;

        case 'De':
          langIndex = 5;
          langQs = parts?.[langIndex];
          return langQs;

        case 'Fr':
          langIndex = 6;
          langQs = parts?.[langIndex];
          return langQs;

        case 'Pt':
          langIndex = 7;
          langQs = parts?.[langIndex];
          return langQs;

        case 'Es':
          langIndex = 8;
          langQs = parts?.[langIndex];
          return langQs;

        case 'Br':
          langIndex = 9;
          langQs = parts?.[langIndex];
          return langQs;

        case 'Pl':
          langIndex = 10;
          langQs = parts?.[langIndex];
          return langQs;

        case 'Zh':
          langIndex = 11;
          langQs = parts?.[langIndex];
          return langQs;

        case 'Ko':
          langIndex = 12;
          langQs = parts?.[langIndex];
          return langQs;

        case 'Ja':
          langIndex = 13;
          langQs = parts?.[langIndex];
          return langQs;
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped></style>
