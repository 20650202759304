<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :hide-overlay="$vuetify.breakpoint.xsOnly"
    :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
  >
    <template v-slot:activator="{ on }">
      <v-fab-transition>
        <v-btn
          v-show="$vuetify.breakpoint.smAndUp || ready"
          :bottom="$vuetify.breakpoint.xsOnly"
          :class="{ 'ma-0': $vuetify.breakpoint.smAndUp }"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
          :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
          color="primary"
          dark
          v-on="on"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">add</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('tenants.add_new_tenant') }}</span>
        </v-btn>
      </v-fab-transition>
    </template>

    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $t('tenants.add_new_tenant') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn dark icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="organization"
            :label="$t('tenants.organization_name')"
            :rules="[$rules.required]"
            autocomplete="organization"
            class="mt-5 organization_name"
            type="text"
            validate-on-blur
            @keyup.enter="saveOrganization"
          ></v-text-field>
        </v-form>

        <v-alert :value="showAlert" class="mt-4" outlined type="error">Tarkista, että olet täyttänyt kaikki pakolliset kentät. </v-alert>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :bottom="$vuetify.breakpoint.xsOnly"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :left="$vuetify.breakpoint.xsOnly"
          color="default"
          @click="dialog = false"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn
          :bottom="$vuetify.breakpoint.xsOnly"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :loading="loading"
          :right="$vuetify.breakpoint.xsOnly"
          color="primary"
          @click="saveOrganization"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddTenantDialog',
  data() {
    return {
      loading: false,
      dialog: false,
      organization: '',
      showAlert: false,
      ready: false,
    };
  },
  methods: {
    async saveOrganization(e) {
      e.preventDefault();
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }
      this.loading = true;
      try {
        await this.$adminApi.addTenant(this.organization);
        this.dialog = false;
        this.$showSuccessNotification(this.$t('tenants.tenant_created_success'));
      } catch (err) {
        this.$handleApiError(err, this.$t('tenants.tenant_creation_failed'));
      }
      this.loading = false;
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.organization = '';
        this.showAlert = false;
        this.$refs.form.reset();
        this.$emit('on-dismiss');
      }
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.xsOnly) {
      setTimeout(() => {
        this.ready = true;
      }, 300);
    } else {
      this.ready = true;
    }
  },
};
</script>

<style scoped></style>
