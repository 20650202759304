<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <template v-slot:activator="{ on }">
      <v-fab-transition>
        <v-btn
          v-on="on"
          color="primary"
          dark
          v-show="$vuetify.breakpoint.smAndUp || ready"
          :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
          :class="{'ma-0': $vuetify.breakpoint.smAndUp}"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">person_add</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('tenants.add_new_user') }}</span>
        </v-btn>
      </v-fab-transition>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('tenants.add_new_user') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-form ref="form">
          <v-text-field
            :rules="[$rules.required, $rules.email, $rules.spacesNotAllowed]"
            validate-on-blur
            class="mt-5 email"
            :label="$t('user_management.email')"
            autocomplete="email"
            type="text"
            v-model="email"
          ></v-text-field>
          <v-text-field
            validate-on-blur
            class="mt-5 first-name"
            :label="$t('user_management.first_name')"
            autocomplete="first-name"
            type="text"
            v-model="firstName"
          ></v-text-field>
          <v-text-field
            validate-on-blur
            class="mt-5 last-name"
            :label="$t('user_management.last_name')"
            autocomplete="family-name"
            type="text"
            v-model="lastName"
          ></v-text-field>
        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">Tarkista, että olet täyttänyt kaikki pakolliset kentät.</v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="addNewUserToTenant"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('add') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import tenantApi from "../../../api/tenant";

  export default {
    name: "AddUserDialog",
    data() {
      return {
        dialog: false,
        email: '',
        firstName: '',
        lastName: '',
        showAlert: false,
        ready: false,
      }
    },
    methods: {
      async addNewUserToTenant() {
        if (!this.$refs.form.validate()) {
          this.showAlert = true;
          return;
        }

        try {
          await tenantApi.addNewUserToTenant({
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
          });
          this.$showSuccessNotification(this.$t('tenants.user_add_success'));
          this.dialog = false;
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('tenants.user_add_fail'));
        }
      },
    },
    watch: {
      dialog(value) {
        if (!value) {
          this.email = '';
          this.showAlert = false;
          this.$refs.form.reset();
          this.$emit('on-dismiss');
        }
      }
    },
    mounted() {
      if (this.$vuetify.breakpoint.xsOnly) {
        setTimeout(() => {
          this.ready = true
        }, 300);
      } else {
        this.ready = true;
      }
    }
  }
</script>

<style scoped>

</style>
